import React from 'react';
import TablePage, {
  TableConfig,
  TableFilter,
  TableHead,
} from '../../../components/table/TablePage';
import { apiDownloadsGet, apiDownloadsGetEmails } from '../../../actions/downloadActions';
import { useSelector } from 'react-redux';
import PageNotFound from '../../pagenotfound/PageNotFound';

const configHeads: TableHead[] = [
  {
    name: '',
    width: '20px',
    key: 'empty',
  },
  {
    name: 'Email',
    width: null,
    key: 'email',
    type: 'string',
  },
  {
    name: 'Video',
    width: null,
    key: 'video',
    type: 'link',
    options: {
      href: '/panel/videos/',
    },
  },
  {
    name: 'Downloaded at',
    width: '160px',
    key: 'created_at',
    type: 'date',
    options: {
      format: 'DD/MM/YYYY HH:mm:ss',
    },
  },
];

const configFilters: TableFilter[] = [
  {
    id: 'Email',
    type: 'select',
    label: 'Email',
    options: {
      label: 'Please select email',
      secured: true,
      onLoadElements: apiDownloadsGetEmails,
    },
  },
  {
    id: 'Downloaded At',
    type: 'date',
    label: 'Downloaded At',
  },
];

const config: TableConfig = {
  title: 'Downloads',
  key: 'downloads',
  functions: {
    loadElementsMethod: apiDownloadsGet,
    convertElementsMethod: (elements: any[]) => [
      ...elements.map(element => {
        return {
          ...element,
          id: element.video ? element.video.id : null,
          email: element.user ? element.user.email : null,
          video: element.video ? element.video.title : null,
        };
      }),
    ],
  },
  options: {
    hasSwitcher: false,
    hasSearch: false,
  },
  heads: configHeads,
  filters: configFilters,
};

function PanelDownloads() {
  const user = useSelector(state => state.user);

  if (user.user && parseInt(user.user.admin) === 0) {
    return <PageNotFound />;
  }

  return <TablePage config={config} />;
}

export default PanelDownloads;
