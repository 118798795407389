import React from 'react';
import { Link } from 'react-router-dom';

function Button({ type, label, loading, onClick, className, to, disabled }: any) {
  if (type === 'link') {
    return (
      <Link to={to} className={`custom-button ${className && className}`}>
        <span className="custom-button-label">{label}</span>
      </Link>
    );
  }

  if (type === 'button') {
    return (
      <button
        disabled={loading || disabled === true}
        className={`custom-button ${loading ? 'loading' : ''} ${className && className}`}
        onClick={onClick}
      >
        <span className="custom-button-label">{label}</span>
        <span className="custom-button-spinner">
          <span className="spinner" />
        </span>
      </button>
    );
  }

  return <div />;
}

export default Button;
