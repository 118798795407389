import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../../../components/ui/Loading';
import { apiUserGetProfile } from '../../../actions/userActions';
import { CLOUDFRONT_URL } from '../../../core/common';
import DeleteProfileModal from '../../../components/modals/DeleteProfileModal';
import SignOutModal from '../../../components/modals/SignOutModal';
import useModal from '../../../hooks/useModal';

function ProfileClient() {
  const token = useSelector(state => state.user.token);

  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<any>(null);

  const [deleteModalOpen, openDeleteModal, closeDeleteModal] = useModal();
  const [signOutModalOpen, openSignOutModal, closeSignOutModal] = useModal();

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = async () => {
    setLoading(true);
    const response = await apiUserGetProfile(token);
    if (response !== null) {
      setProfile(response.profile);
      setLoading(false);
    }
  };

  if (loading || profile === null || profile.user === null) {
    return <Loading />;
  }

  return (
    <div className="stringer-page" style={{ marginTop: 20 }}>
      <DeleteProfileModal open={deleteModalOpen} onClose={closeDeleteModal} editable={false} />
      <SignOutModal open={signOutModalOpen} onClose={closeSignOutModal} />
      <div className="stringer-page-inner">
        <div className="stringer-page-top">
          {profile.user.image === null && (
            <div
              className="stringer-page-top-avatar"
              style={{ backgroundImage: 'url(/images/stringer-avatar.svg)' }}
            />
          )}
          {profile.user.image !== null && (
            <div
              className="stringer-page-top-avatar"
              style={{ backgroundImage: `url(${CLOUDFRONT_URL}avatars/${profile.user.image})` }}
            />
          )}
          <div className="stringer-page-top-info">
            <span className="stringer-page-top-info-name">
              {profile.user.first_name} {profile.user.last_name}
            </span>
            <span className="stringer-page-top-info-location">
              <i className="fa fa-map-marker" /> {profile.country}
            </span>
          </div>
        </div>
        <div className="stringer-page-content">
          <div className="stringer-page-aside">
            <div className="stringer-page-aside-block-1">
              <div className="stringer-page-info-row">
                <div className="stringer-page-info-element">
                  <span className="stringer-page-info-element-value">0</span>
                  <span className="stringer-page-info-element-label">Task created</span>
                </div>
                <div className="stringer-page-info-element">
                  <span className="stringer-page-info-element-value">0</span>
                  <span className="stringer-page-info-element-label">Task completed</span>
                </div>
              </div>
            </div>
            <div className="stringer-page-aside-block-3">
              {
                <span onClick={openSignOutModal} className="stringer-page-aside-sign-out">
                  Sign Out
                </span>
              }
              <span onClick={openDeleteModal} className="stringer-page-aside-delete">
                Delete account
              </span>
            </div>
          </div>
          <div className="stringer-page-right" style={{ top: 0 }}>
            <div className="stringer-page-right-content">
              <div className="stringer-page-right-content-col">
                <div className="stringer-page-block">
                  <div className="stringer-page-block-top">
                    <span className="stringer-page-block-title">General information</span>
                  </div>
                  <div className="stringer-page-block-element">
                    <span className="stringer-page-block-element-label">Country:</span>
                    <div className="stringer-page-block-element-value">
                      <span className="stringer-page-block-element-value-text">
                        {profile.country}
                      </span>
                    </div>
                  </div>
                  <div className="stringer-page-block-element">
                    <span className="stringer-page-block-element-label">Phone number:</span>
                    <div className="stringer-page-block-element-value">
                      <span className="stringer-page-block-element-value-text">
                        {profile.contact_phone}
                      </span>
                    </div>
                  </div>
                  <div className="stringer-page-block-element">
                    <span className="stringer-page-block-element-label">Organization:</span>
                    <div className="stringer-page-block-element-value">
                      <span className="stringer-page-block-element-value-text">
                        {profile.organization}
                      </span>
                    </div>
                  </div>
                  <div className="stringer-page-block-element">
                    <span className="stringer-page-block-element-label">Position:</span>
                    <div className="stringer-page-block-element-value">
                      <span className="stringer-page-block-element-value-text">
                        {profile.position}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="stringer-page-right-content-col stringer-page-rating">
                <span className="stringer-page-rating-title">Work History</span>
                <div className="stringer-page-rating-empty">
                  <div className="stringer-page-rating-empty-icon" />
                  <span className="stringer-page-rating-empty-text">
                    Reviews about working with you will be displayed here
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileClient;
