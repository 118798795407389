import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../../components/ui/Loading';
import Brief from '../../components/Brief';
import { apiBriefsGetPublic } from '../../actions/briefActions';
import ReactPaginate from 'react-paginate';
import BriefsFilters from './components/BriefsFilters';

function Briefs() {
  const token = useSelector(state => state.user.token);

  const [loading, setLoading] = useState(true);

  const [briefs, setBriefs] = useState<any>([]);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    loadBriefs();
  }, [page, filters]);

  const loadBriefs = async () => {
    setLoading(true);

    const response: any = await apiBriefsGetPublic({
      limit: 32,
      offset: page * 32,
      filters: JSON.stringify(
        filters.map((filter: any) =>
          JSON.stringify({
            id: filter.id,
            value: filter.value,
          })
        )
      ),
    });
    setBriefs(response.events);
    setCount(response.count);
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  const pagination = (
    <div className="table-page-paginations">
      <ReactPaginate
        forcePage={page}
        breakLabel="..."
        nextLabel=">"
        onPageChange={event => {
          setPage(event.selected);
          window.scrollTo(0, 0);
        }}
        pageRangeDisplayed={5}
        pageCount={Math.ceil(count / 32)}
        previousLabel="<"
      />
    </div>
  );

  return (
    <>
      <div className="video-page-top">
        <span className="video-page-top-title">Briefs</span>
        <div className="video-page-top-right">{count > 32 && pagination}</div>
      </div>
      <div className="gallery-videos">
        <div className="gallery-videos-left">
          <BriefsFilters
            filters={filters}
            onChange={(values: []) => {
              setFilters([...values]);
            }}
          />
        </div>
        <div className="gallery-videos-right">
          {loading ? (
            <div className="table-page-loader">
              <div className="lds-ripple">
                <div />
                <div />
              </div>
            </div>
          ) : (
            <div className="videos row">
              {briefs.map((brief: any) => {
                return (
                  <Brief
                    brief={brief}
                    fields={['location_text', 'filming_date']}
                    link={'/brief/'}
                  />
                );
              })}
            </div>
          )}
          <div className="videos-page-bottom-pagination">
            {count > 32 && !loading && pagination}
          </div>
        </div>
      </div>
    </>
  );
}

export default Briefs;
