import React from 'react';
import TablePage, { TableConfig } from '../../../components/table/TablePage';
import { useSelector } from 'react-redux';
import { apiPartnersGetInfo } from '../../../actions/partnesActions';

const configHeads = [
  {
    name: '',
    width: '20px',
    key: 'empty',
  },
  {
    name: 'Name',
    width: null,
    key: 'name',
    type: 'link',
    options: {
      href: '/panel/partner/',
    },
  },
  {
    name: 'Email',
    width: '170px',
    key: 'email',
    type: 'string',
  },
  {
    name: 'Type',
    width: '100px',
    key: 'type',
    type: 'string',
  },
  {
    name: 'Receive (1 day)',
    width: '100px',
    key: 'receive1Day',
    type: 'string',
  },
  {
    name: 'Receive (2 day)',
    width: '100px',
    key: 'receive2Day',
    type: 'string',
  },
  {
    name: 'Send (1 day)',
    width: '100px',
    key: 'send1day',
    type: 'string',
  },
  {
    name: 'Send (2 day)',
    width: '100px',
    key: 'send1day',
    type: 'string',
  },
];

const config: TableConfig = {
  title: 'Partners',
  key: 'partners',
  functions: {
    loadElementsMethod: apiPartnersGetInfo,
    convertElementsMethod: null,
  },
  options: {
    hasSearch: false,
    hasSwitcher: false,
  },
  filters: [],
  heads: configHeads,
};

function PanelPartners() {
  const token = useSelector(state => state.user.token);

  return (
    <>
      <TablePage config={config} />
    </>
  );
}

export default PanelPartners;
