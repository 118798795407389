import React, { useEffect, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { apiVideoDownload, apiVideoGetGallery } from '../../../actions/videoActions';
import { isEmpty } from '../../../core/helpers';
import Loading from '../../../components/ui/Loading';
import moment from 'moment';
import VideoPlayer from '../../../components/ui/VideoPlayer';
import { CLOUDFRONT_URL } from '../../../core/common';
import { useSelector } from 'react-redux';
import PageNotFound from '../../pagenotfound/PageNotFound';

function GalleryPage() {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const userId = useSelector(state => state.user.user?.id);
  const role = useSelector(state => state.user.user?.role);
  const companyId = useSelector(state => state.user.user?.company_id);
  const token = useSelector(state => state.user.token);

  const [loading, setLoading] = useState(true);
  const [video, setVideo] = useState<any>(null);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [downloadLink, setDownloadLink] = useState(null);
  const [downloadFiles, setDownloadFiles] = useState([]);

  const [files, setFiles] = useState<any>([]);
  const [activeFile, setActiveFile] = useState<any>(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    loadData();
  }, [id]);

  const loadData = async () => {
    if (id !== undefined) {
      const galleryToken = searchParams.get('token');
      const response: any = await apiVideoGetGallery(token, id.toString(), galleryToken);
      if (response.status) {
        setVideo(response.video);
        setCategories(response.categories);
        setTags(response.tags);
        setDownloadLink(response.downloadLink);
        setDownloadFiles(response.downloadFiles);

        if (response.files.length > 0) {
          setActiveFile(response.files[0]);
        }
        setFiles(response.files);

        setLoading(false);
      } else {
        setNotFound(true);
      }
    }
  };

  const download = async (link: string) => {
    await apiVideoDownload(token, video.id);
    window.open(link, 'Download');
  };

  const getPlayer = () => {
    return (
      <div className="video-page-player">
        <VideoPlayer src={activeFile.previewPath} />
      </div>
    );
  };

  if (notFound) {
    return <PageNotFound />;
  }

  if (loading || video == null || activeFile === null) {
    return <Loading />;
  }

  return (
    <>
      <div className="video-page-top">
        <Link to={'/gallery'} className="video-page-top-back-icon">
          <i className="fa fa-angle-left" />
        </Link>
        <span className="video-page-top-title">Gallery</span>
      </div>
      {video?.status === 2 && video?.user_id === userId && (
        <div className="create-page-warning-block">
          <img src="/images/warning-check.svg" alt="" className="create-page-warning-block-icon" />
          <div className="create-page-warning-block-right">
            <span className="create-page-warning-block-title">Your video has been approved</span>
            <span className="create-page-warning-block-text">
              Your video has been submitted for sale to our partners. You cannot edit videos. If you
              want to change or delete the video, you can write to
              <a
                href="mailto:info@stringershub.com"
                className="create-page-warning-block-text-link"
              >
                info@stringershub.com
              </a>
            </span>
          </div>
        </div>
      )}
      <div className="gallery">
        <div className="gallery-left">
          {activeFile !== null && <div className="gallery-player">{getPlayer()}</div>}
          <div className="gallery-files">
            {files.map((file: any) => {
              return (
                <div
                  className={`gallery-file ${activeFile && file.id === activeFile.id && 'active'}`}
                  onClick={() => {
                    setActiveFile(file);
                  }}
                >
                  <img
                    src={`${CLOUDFRONT_URL}${file.thumbPath}`}
                    alt=""
                    className="gallery-file-preview"
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="gallery-right">
          <h1 className="gallery-title">{video.title}</h1>
          {role === 'admin' && companyId === 1 && (
            <a
              className="gallery-link"
              href={`https://stringershub.com/panel/videos/${video.id}`}
              target={'_blank'}
            >
              https://stringershub.com/panel/videos/{video.id}
            </a>
          )}
          <div className="gallery-elements">
            <div className="gallery-element">
              <span className="gallery-element-label">Uploaded at:</span>
              <span className="gallery-element-value">
                {moment(video.created_at).format('DD/MM/YYYY')}
              </span>
            </div>
            {!isEmpty(video.filming_date) && (
              <div className="gallery-element">
                <span className="gallery-element-label">Filming Date:</span>
                <span className="gallery-element-value">
                  {moment(video.filming_date).format('DD/MM/YYYY')}
                </span>
              </div>
            )}
            {!isEmpty(video.location_text) && (
              <div className="gallery-element">
                <span className="gallery-element-label">Filming Location:</span>
                <span className="gallery-element-value">{video.location_text}</span>
              </div>
            )}
            <div className="gallery-element">
              <span className="gallery-element-label">Categories:</span>
              <span className="gallery-element-value">{categories.join(', ')}</span>
            </div>
            <div className="gallery-element">
              <span className="gallery-element-label">Tags:</span>
              <span className="gallery-element-value">
                {tags.map((tag: any) => `#${tag}`).join(', ')}
              </span>
            </div>
          </div>
          {!isEmpty(video.description) && (
            <div
              className="gallery-description"
              dangerouslySetInnerHTML={{ __html: video.description.replace(/\n/g, '<br />') }}
            />
          )}
          {downloadFiles.length > 0 && (
            <div className="gallery-download">
              <span className="gallery-download-title">Download original files</span>
              <div className="gallery-download-files">
                {downloadFiles.map((file: any) => {
                  return (
                    <span className="gallery-download-file">
                      {file.name}{' '}
                      <i
                        className="fa fa-download"
                        onClick={() => {
                          download(file.link);
                        }}
                      />
                    </span>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default GalleryPage;
