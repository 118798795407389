import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CLOUDFRONT_URL } from '../core/common';
import { isEmpty } from '../core/helpers';

function Task({ task }: any) {
  return (
    <div className="task-card">
      <div className="task-card-inner">
        <Link to={`/tasks/${task.hash}`} className="task-card-image">
          <div className="task-card-image-inner">
            <img src={`${CLOUDFRONT_URL}${task.static_image}`} alt="" className="task-card-thumb" />
          </div>
        </Link>
        <div className="task-card-content-area">
          <Link to={`/tasks/${task.hash}`} className="task-card-title">
            {task.title}
          </Link>
          <div className="task-card-content">
            <div className="task-card-content-element row">
              <span className="task-card-content-element-label">Location</span>
              <span className="task-card-content-element-value">
                {!isEmpty(task.location_text) ? task.location_text : 'Global'}
              </span>
            </div>
            <div className="task-card-content-element row">
              <span className="task-card-content-element-label">Deadline</span>
              <span className="task-card-content-element-value">
                {moment(task.deadline).format('DD/MM/YYYY')}
              </span>
            </div>
            {task.stringer_budget && (
              <div className="task-card-content-element row">
                <span className="task-card-content-element-label">Budget</span>
                <span className="task-card-content-element-value">{task.stringer_budget}$</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Task;
