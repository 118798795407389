import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formCheckErrors, formGenerateErrorsObject } from '../../core/form';
import Modal from '../ui/Modal';
import Button from '../ui/Button';
import Field from '../ui/Field';
import { apiStringerInvite } from '../../actions/stringerActions';

const errorsList = {
  email: 'required',
};

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  location: null,
};

function InviteStringerModal({ onClose, open }: any) {
  const token = useSelector(state => state.user.token);

  const navigate = useNavigate();

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList));
  const [submitLoading, setSubmitLoading] = useState(false);

  const changeField = (field: string, value: any) => {
    setValues({ ...values, [field]: value });
    setErrors({ ...errors, [field]: '' });
  };

  const submit = async () => {
    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({ ...errorResponse.errors });
      return;
    }
    setSubmitLoading(true);
    const apiResponse = await apiStringerInvite(token, values);
    if (apiResponse.status) {
      alert(apiResponse.message);
      window.location.reload();
    } else {
      alert(apiResponse.message);
      setSubmitLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={
        <div className="modal-window">
          <div className="modal-window-top">
            <span className="modal-window-top-title">Invite stringer</span>
            <div className="modal-window-top-close" onClick={() => onClose()} />
          </div>
          <div className="modal-window-content">
            <div className="modal-window-content-inner">
              <div className="modal-window-row row">
                <Field
                  id="inviteEmail"
                  label="Email"
                  type="input"
                  required={true}
                  error={errors.email}
                  value={values.email}
                  onChange={value => changeField('email', value)}
                />
              </div>
              <div className="modal-window-row row">
                <div className="modal-window-col">
                  <Field
                    id="profileFirstName"
                    label="First Name "
                    type="input"
                    error={errors.firstName}
                    value={values.firstName}
                    onChange={value => changeField('firstName', value)}
                  />
                </div>
                <div className="modal-window-col">
                  <Field
                    id="profileLastName"
                    label="Last Name "
                    type="input"
                    error={errors.lastName}
                    value={values.lastName}
                    onChange={value => changeField('lastName', value)}
                  />
                </div>
              </div>
              <div className="modal-window-row row">
                <div className="modal-window-col">
                  <Field
                    id="profileLocation"
                    label="Location "
                    type="location"
                    error={errors.location}
                    value={values.location}
                    onChange={value => changeField('location', value)}
                  />
                </div>
                <div className="modal-window-col">
                  <Field
                    id="profilePhone"
                    label="Phone "
                    type="input"
                    error={errors.phone}
                    value={values.phone}
                    onChange={value => changeField('phone', value)}
                  />
                </div>
              </div>
            </div>
            <div className="modal-window-bottom">
              <div className="modal-window-bottom-buttons">
                <button className="modal-window-bottom-cancel-button" onClick={onClose}>
                  Cancel
                </button>
                <Button
                  className={'modal-window-bottom-submit-button'}
                  label={'Invite'}
                  loading={submitLoading}
                  onClick={submit}
                  type={'button'}
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default InviteStringerModal;
