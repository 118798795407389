import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../../components/ui/Loading';
import { useSelector } from 'react-redux';
import { apiEmployeeGet } from '../../../../actions/employeeAction';
import moment from 'moment';

function PanelEmployeePage() {
  const token = useSelector(state => state.user.token);
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    loadEmployee();
  }, []);

  const loadEmployee = async () => {
    if (token && id) {
      const response = await apiEmployeeGet(token, Number(id));
      setLoading(false);
      setEmployee(response);
    }
  };

  if (loading || employee === null) {
    return <Loading />;
  }

  return (
    <>
      <div className="video-page-top">
        <div
          className="video-page-top-back-icon"
          onClick={() => {
            navigate('/panel/employees');
          }}
        >
          <i className="fa fa-angle-left" />
        </div>
        <span className="video-page-top-title">Employee</span>
      </div>
      <div className="em-area-top">
        <div className="em-area-icons">
          {/*
          <div className="em-area-icon">
            <i className="fa fa-pencil"/>
          </div>
          */}
          <div className="em-area-icon">
            <i className="fa fa-trash" />
          </div>
        </div>
        <div
          className="em-area-avatar"
          style={{ backgroundImage: 'url(/images/stringer-avatar.svg)' }}
        >
          <div className="em-area-avatar-circle" />
        </div>
        <div className="em-area-right">
          <div className="em-area-right-col">
            <span className="em-area-name">
              {employee.user.first_name} {employee.user.last_name}
            </span>
            <span className="em-area-activity">
              Created at {moment(employee.created_at).format('DD/MM/YYYY')}
            </span>
            <span className="em-area-position">{employee.position}</span>
          </div>
          <div className="em-area-right-col">
            <span className="em-area-email">
              e-mail: <a href="/">{employee.user.email}</a>
            </span>
            <span className="em-area-phone">
              phone: <a href="/">{employee.phone}</a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default PanelEmployeePage;
