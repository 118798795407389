import axios from 'axios';
import { API_URL } from '../core/common';

export function apiLenSearch(cancelToken: any, input: string) {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .get(API_URL + `/len.search?text=${input}`, {
        cancelToken,
      })
      .then(function (response) {
        resolve(response.data.lens);
      })
      .catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error);
        }
      });
  });
}

export function apiMicrophoneSearch(cancelToken: any, input: string) {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .get(API_URL + `/microphone.search?text=${input}`, {
        cancelToken,
      })
      .then(function (response) {
        resolve(response.data.microphones);
      })
      .catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error);
        }
      });
  });
}

export function apiCameraSearch(cancelToken: any, input: string) {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .get(API_URL + `/camera.search?text=${input}`, {
        cancelToken,
      })
      .then(function (response) {
        resolve(response.data.cameras);
      })
      .catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error);
        }
      });
  });
}

export function apiSoftwareSearch(cancelToken: any, input: string) {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .get(API_URL + `/software.search?text=${input}`, {
        cancelToken,
      })
      .then(function (response) {
        resolve(response.data.softwares);
      })
      .catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error);
        }
      });
  });
}

export function apiLanguagesGet() {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .get(API_URL + '/languages.get')
      .then(function (response) {
        resolve(response.data.languages);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function apiSkillsGet() {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .get(API_URL + '/skills.get')
      .then(function (response) {
        resolve(response.data.skills);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
