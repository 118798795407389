import axios from 'axios';
import { API_URL } from '../core/common';
import { TableLoadElementsParams } from '../components/table/TablePage';

export function apiLogsGet({
  cancelToken,
  token,
  search,
  filters,
  offset,
  limit,
}: TableLoadElementsParams) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL + `/logs.get?offset=${offset}&limit=${limit}&search=${search}&filters=${filters}`,
        {
          cancelToken,
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error);
        }
      });
  });
}
