import axios from 'axios';
import { API_URL } from '../core/common';
import { TableLoadElementsParams } from '../components/table/TablePage';
import moment from 'moment';

export const apiTasksGet = ({
  cancelToken,
  token,
  sortDir,
  sortColumn,
  search,
  filters,
  offset,
}: TableLoadElementsParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/tasks.get?offset=${offset}&limit=${100}&sortDir=${sortDir}&sortColumn=${sortColumn}&search=${search}&filters=${filters}`,
        {
          cancelToken,
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error);
        }
      });
  });
};

export const apiTaskGet = (token: string, id: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/task.get?id=${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTaskGetTypes = (): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/task.get_types`, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(function (response) {
        resolve(response.data.types);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTasksGetPublic = (token: string, { limit = 50, offset = 0, filters = '' }) => {
  return new Promise((resolve, reject) => {
    const url = token !== null ? '/tasks.get_public_access' : '/tasks.get_public';
    axios
      .get(API_URL + `${url}?limit=${limit}&offset=${offset}&filters=${filters}`, {
        headers: {
          Authorization: 'Bearer ' + token,
          Accept: 'application/json',
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTaskCreate = (
  token: string,
  {
    title,
    description,
    location,
    deadline,
    producer_id,
    client_id,
    contact_phone,
    contact_name,
    budget,
    types,
  }: any
): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + '/task.create',
        {
          title,
          description,
          location_place_id: location?.location_place_id,
          deadline: deadline === null ? null : moment(deadline).format('YYYY-MM-DD'),
          producer_id,
          client_id,
          contact_phone,
          contact_name,
          budget,
          types: types.join(','),
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTaskUpdate = (
  token: string,
  {
    id,
    title,
    description,
    location,
    deadline,
    contact_phone,
    contact_name,
    budget,
    stringer_budget,
    types,
    material_filmed,
    material_accepted,
    customer_sent_payment,
    stringer_received_payment,
    links,
  }: any
): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + '/task.update',
        {
          id,
          title,
          description,
          location_place_id: location?.location_place_id,
          deadline: deadline === null ? null : moment(deadline).format('YYYY-MM-DD'),
          contact_phone,
          contact_name,
          budget,
          stringer_budget,
          types: types.join(','),
          material_filmed: +material_filmed,
          material_accepted: +material_accepted,
          customer_sent_payment: +customer_sent_payment,
          stringer_received_payment: +stringer_received_payment,
          links: links.join(','),
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTaskUpdateStatus = (token: string, id: number, status: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + '/task.update_status',
        {
          id,
          status,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTaskInviteStringers = (token: string, id: number, stringers: number[]): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + '/task.invite_stringers',
        {
          task_id: id,
          stringers: stringers.join(','),
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTaskFindPublic = (token: string | null, hash: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/task.get_public?hash=${hash}`, {
        headers: {
          Accept: 'application/json',
          ...(token ? { Authorization: 'Bearer ' + token } : {}),
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTaskSelectExecutor = (token: string, task_id: number, stringer_id: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + '/task.select_executer',
        {
          task_id,
          stringer_id,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTaskDeclineExecutor = (token: string, task_id: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + '/task.decline_executer',
        {
          task_id,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTaskCreateNote = (token: string, task_id: number, note: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + '/task.create_note',
        {
          task_id,
          note,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTaskGetNotes = (token: string, task_id: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/task.get_notes?task_id=${task_id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data.notes);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export function apiTaskCountrySearch(cancelToken: any, input: string) {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .get(API_URL + `/task.search_countries?text=${input}`, {
        cancelToken,
      })
      .then(function (response) {
        resolve(response.data.countries);
      })
      .catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error);
        }
      });
  });
}

export function apiTaskStateSearch(cancelToken: any, input: string) {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .get(API_URL + `/task.search_states?text=${input}`, {
        cancelToken,
      })
      .then(function (response) {
        resolve(response.data.states);
      })
      .catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error);
        }
      });
  });
}

export function apiTaskCitiesSearch(cancelToken: any, input: string) {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .get(API_URL + `/task.search_cities?text=${input}`, {
        cancelToken,
      })
      .then(function (response) {
        resolve(response.data.cities);
      })
      .catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error);
        }
      });
  });
}
