import React from 'react';
import { Link } from 'react-router-dom';

function PageNotFound() {
  return (
    <div className="error-page">
      <div className="error-page-container">
        <h1 className="error-page-title">Oh, shoot</h1>
        <div className="error-page-image-area">
          <div className="error-page-image">
            <span className="error-page-image-num left">4</span>
            <span className="error-page-image-num right">4</span>
          </div>
        </div>
        <span className="error-page-sub-title">Something’s wrong here...</span>
        <div className="error-page-text">
          We can’t find the page you’re looking for. <br />
          You can go to the <Link to={'/'}>mainpage</Link>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
