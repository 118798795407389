import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { apiVideosGetGallery } from '../../actions/videoActions';
import Video from '../../components/Video';
import ReactPaginate from 'react-paginate';
import GalleryFilters from './components/GalleryFilters';
import queryString from 'query-string';
import { convertFilters } from '../../core/helpers';
import FilterMenu from './components/FilterMenu';
import { useSelector } from 'react-redux';
import PageNotFound from '../pagenotfound/PageNotFound';

function Gallery() {
  const [_searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const token = useSelector(state => state.user.token);
  const role = useSelector(state => state.user.user?.role);
  const user = useSelector(state => state.user.user);

  const [loading, setLoading] = useState(true);
  const [videos, setVideos] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('Newest first');

  useEffect(() => {
    const result: any = [];
    const query: any = queryString.parse(window.location.search);

    Object.keys(query).forEach((key: string) => {
      if (key !== 'search' && key !== 'page' && key !== 'sort') {
        const values = query[key].split(',');
        values.forEach((value: any) => {
          result.push({
            id: key,
            value,
            label: key,
          });
        });
      }
    });

    if (query['search'] !== undefined) {
      setSearch(query['search']);
    }

    if (query['sort'] !== undefined) {
      setSort(query['sort']);
    }

    if (query['page'] !== undefined) {
      setPage(parseInt(query['page']) - 1);
    }

    setFilters(result);
  }, []);

  useEffect(() => {
    setSearchParams(convertFilters(filters, search, page, '', sort));
    loadData();
  }, [page, id, filters, search, sort]);

  const loadData = async () => {
    setLoading(true);
    const response: any = await apiVideosGetGallery(
      token,
      search,
      JSON.stringify(
        filters.map((filter: any) =>
          JSON.stringify({
            id: filter.id,
            value: filter.value,
          })
        )
      ),
      sort,
      page * 32
    );

    if (response.status) {
      setVideos(response.videos);
      setCount(response.count);
      setLoading(false);
    }
  };

  const pagination = (
    <div className="table-page-paginations">
      <ReactPaginate
        forcePage={page}
        breakLabel="..."
        nextLabel=">"
        onPageChange={event => {
          setPage(event.selected);
          window.scrollTo(0, 0);
        }}
        pageRangeDisplayed={5}
        pageCount={Math.ceil(count / 32)}
        previousLabel="<"
      />
    </div>
  );

  const filter = (
    <GalleryFilters
      filters={filters}
      onChange={(values: []) => {
        setFilters([...values]);
      }}
      search={search}
      onChangeSearch={(_search: string) => {
        setSearch(_search);
      }}
      sort={sort}
      onChangeSort={(_sort: string) => {
        setSort(_sort);
      }}
    />
  );

  if (role === 'client' && user?.hasGalleryAccess === 0) {
    return (
      <div className="error-page">
        <div className="error-page-container">
          <h1 className="error-page-title">Oh, shoot</h1>
          <div className="error-page-image-area">
            <div className="error-page-image" />
          </div>
          <span className="error-page-sub-title">You do not have access to this page.</span>
          <div className="error-page-text">
            To access to our video library, please contact us at{' '}
            <a className="blue" href="mailto:info@stringershub.com">
              info@stringershub.com
            </a>
          </div>
        </div>
      </div>
    );
  }

  if (!token || (role !== 'admin' && user?.hasGalleryAccess === 0)) {
    return <PageNotFound />;
  }

  return (
    <>
      <div className="video-page-top">
        <span className="video-page-top-title">Gallery</span>
        <div className="video-page-top-right">{count > 32 && pagination}</div>
        <FilterMenu filter={filter} />
      </div>
      <div className="gallery-videos">
        <div className="gallery-videos-left">{filter}</div>
        <div className="gallery-videos-right">
          {loading ? (
            <div className="table-page-loader">
              <div className="lds-ripple">
                <div />
                <div />
              </div>
            </div>
          ) : (
            <div className="videos row">
              {videos.map((video: any) => {
                return (
                  <Video
                    video={video}
                    fields={['location_text', 'filming_date', 'created_at']}
                    link={`/gallery/${video.hash}`}
                  />
                );
              })}
            </div>
          )}
          <div className="videos-page-bottom-pagination">
            {count > 32 && !loading && pagination}
          </div>
        </div>
      </div>
    </>
  );
}

export default Gallery;
