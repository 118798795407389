import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { formGenerateErrorsObject } from '../../../../../core/form';
import Field from '../../../../../components/ui/Field';
import Button from '../../../../../components/ui/Button';
import { getDateFromString } from '../../../../../core/helpers';
import { apiBriefClose, apiBriefUpdate } from '../../../../../actions/briefActions';
import { useSelector } from 'react-redux';

const errorsList = {
  title: 'required',
  description: 'required',
};

function BriefPageGeneralInfo({ brief, onChangeStatus, onChangeTitle, onChangeLocationText }: any) {
  const token = useSelector(state => state.user.token);

  const [values, setValues] = useState<any>({
    id: brief.id,
    title: brief.title,
    description: brief.description,
    location: {
      location_text: brief.location_text,
      location_place_id: brief.location_place_id,
      location_lat: brief.location_lat,
      location_lng: brief.location_lng,
      location_country: brief.location_country,
      location_city: brief.location_city,
      location_state: brief.location_state,
    },
    startDate: getDateFromString(brief.start_date),
    deadline: getDateFromString(brief.deadline),
  });
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList));
  const [loading, setLoading] = useState(true);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [closeLoading, setCloseLoading] = useState(false);

  const changeField = (field: string, value: any) => {
    setValues({ ...values, [field]: value });
    setErrors({ ...errors, [field]: '' });
  };

  const save = async () => {
    setSubmitLoading(true);

    await apiBriefUpdate(
      token,
      values.id,
      values.title,
      values.description,
      values.location,
      values.startDate,
      values.deadline
    );

    setSubmitLoading(false);
  };

  const close = async () => {
    setCloseLoading(true);
    await apiBriefClose(token, [values.id]);
    onChangeStatus(2);
    setCloseLoading(false);
  };

  return (
    <div className="task-page-content">
      <div className="task-page-aside">
        <div className="task-page-aside-elements">
          <span className="task-page-aside-element">
            Start date: <b>{moment(values.startDate).format('DD/MM/YYYY')}</b>
          </span>
          <span className="task-page-aside-element">
            Deadline: <b>{moment(values.deadline).format('DD/MM/YYYY')}</b>
          </span>
        </div>
      </div>
      <div className="task-page-right">
        <div className="task-page-right-content">
          <div className="task-page-right-content-col">
            <div className="task-page-block">
              <div className="task-page-block-top">
                <span className="task-page-block-title">General Information</span>
              </div>
              <Field
                className={'create-page-block-element'}
                id="briefTitle"
                label="Title *"
                type="input"
                error={errors.title}
                value={values.title}
                onChange={value => {
                  changeField('title', value);
                  onChangeTitle(value);
                }}
              />
              <Field
                className={'create-page-block-element'}
                id="briefLocation"
                label="Location"
                type="location"
                value={values.location}
                onChange={value => {
                  changeField('location', value);
                  onChangeLocationText(value ? value.location_text : null);
                }}
              />
              <Field
                className={'create-page-block-element'}
                id="briefStartDate"
                label="Start Date *"
                type="datetime"
                error={errors.startDate}
                value={values.startDate}
                onChange={value => changeField('startDate', value)}
              />
              <Field
                className={'create-page-block-element'}
                id="briefDeadline"
                label="Deadline"
                type="datetime"
                value={values.deadline}
                onChange={value => changeField('deadline', value)}
              />
            </div>
          </div>
          <div className="task-page-right-content-col">
            <div className="task-page-block">
              <div className="task-page-block-top">
                <span className="task-page-block-title">Description</span>
              </div>
              <div className="task-page-fields">
                <Field
                  className={'task-page-field'}
                  id="briefDescription"
                  label="Description *"
                  type="textarea"
                  error={errors.description}
                  value={values.description}
                  onChange={value => changeField('description', value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="task-page-bottom">
          {brief.status !== 2 && (
            <Button
              className={'gray'}
              label={'Close brief'}
              loading={closeLoading}
              onClick={close}
              type={'button'}
            />
          )}
          <div className="bottom-area-right">
            <Button
              className={'blue bottom-area-submit-button'}
              label={'Save'}
              loading={submitLoading}
              onClick={save}
              type={'button'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BriefPageGeneralInfo;
