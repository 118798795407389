import React, { useEffect, useState } from 'react';
import { Link, NavLink, useParams } from 'react-router-dom';
import { apiVideoGetGallery } from '../../../actions/videoActions';
import { isEmpty } from '../../../core/helpers';
import Loading from '../../../components/ui/Loading';
import moment from 'moment';
import VideoPlayer from '../../../components/ui/VideoPlayer';
import { CLOUDFRONT_URL } from '../../../core/common';
import { apiBriefsFindPublic } from '../../../actions/briefActions';

function BriefPage() {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [brief, setBrief] = useState<any>(null);

  const [files, setFiles] = useState<any>([]);
  const [activeFile, setActiveFile] = useState<any>(null);

  useEffect(() => {
    loadData();
  }, [id]);

  const loadData = async () => {
    if (id !== undefined) {
      const response: any = await apiBriefsFindPublic(id.toString());
      if (response.status) {
        setBrief(response.event);
        setLoading(false);
      } else {
      }
    }
  };

  if (loading || brief == null) {
    return <Loading />;
  }

  return (
    <>
      <div className="video-page-top">
        <Link to={'/briefs'} className="video-page-top-back-icon">
          <i className="fa fa-angle-left" />
        </Link>
        <span className="video-page-top-title">Brief</span>
      </div>
      <div className="brief">
        <div className="brief-left">
          <img src={`${CLOUDFRONT_URL}${brief.static_image}`} alt="" className="brief-left-image" />
        </div>
        <div className="brief-right">
          <h1 className="brief-title">{brief.title}</h1>
          <div className="brief-elements">
            <div className="brief-element">
              <span className="brief-element-label">Event Date:</span>
              <span className="brief-element-value">
                {moment(brief.event_date).format('DD/MM/YYYY')}
              </span>
            </div>
            <div className="brief-element">
              <span className="brief-element-label">Deadline:</span>
              <span className="brief-element-value">
                {moment(brief.deadline).format('DD/MM/YYYY')}
              </span>
            </div>
            <div className="brief-element">
              <span className="brief-element-label">Location:</span>
              <span className="brief-element-value">{brief.location_text}</span>
            </div>
          </div>
          {!isEmpty(brief.description) && (
            <div
              className="brief-description"
              dangerouslySetInnerHTML={{ __html: brief.description.replace(/\n/g, '<br />') }}
            />
          )}
          <NavLink to={'/video/upload'} className="brief-upload-button">
            Upload video
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default BriefPage;
