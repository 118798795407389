import React from 'react';
import { formatDate } from '../../core/helpers';
import { Link } from 'react-router-dom';
import { TableActionLoadings, TableAction, TableFieldOptions, TableConfig } from './TablePage';

type TableProps = {
  sortColumn: string | null;
  sortDir: 'ASC' | 'DESC';
  config: TableConfig;
  values: any[];
  selectedElements: any[];
  onChangeSelectedElements?: (elements: any[]) => void;
  onAction?: (action: TableAction, ids: number[]) => void;
  actionLoadings?: TableActionLoadings;
  loading: boolean;
  onChangeSort: (sortDir: 'ASC' | 'DESC', sortColumn: string) => void;
};

function Table({
  config,
  values,
  selectedElements,
  onChangeSelectedElements,
  onAction,
  actionLoadings,
  loading,
  sortColumn,
  sortDir,
  onChangeSort,
}: TableProps) {
  const selectElement = (id: any) => {
    let elements = selectedElements.slice();

    if (elements.indexOf(id) === -1) {
      elements.push(id);
    } else {
      elements.splice(elements.indexOf(id), 1);
    }

    if (onChangeSelectedElements) {
      onChangeSelectedElements(elements);
    }
  };

  const selectAll = (checked: boolean) => {
    let elements = [];

    if (checked) {
      elements = values.map(value => value.id);
    }

    if (onChangeSelectedElements) {
      onChangeSelectedElements(elements);
    }
  };

  const getAction = (name: string): TableAction | undefined => {
    return config.actions ? config.actions.find(action => action.name === name) : undefined;
  };

  if (loading) {
    return (
      <div className="table-page-loader">
        <div className="lds-ripple">
          <div />
          <div />
        </div>
      </div>
    );
  }

  return (
    <div className="table-page-content">
      <table>
        <tr>
          {config.heads.map(head => {
            let style = {};
            if (head.width !== null) {
              style = { width: head.width };
            }

            if (head.type === 'checkbox') {
              return (
                <th style={{ width: '40px' }}>
                  <label className="table-page-checkbox">
                    <input
                      type="checkbox"
                      checked={values.length === selectedElements.length}
                      onChange={event => selectAll(event.target.checked)}
                    />
                    <div className="table-page-checkbox-checkmark">
                      <div className="table-page-checkbox-checkmark-inner" />
                    </div>
                  </label>
                </th>
              );
            }

            return (
              <th
                style={style}
                onClick={() => {
                  if (head.sortable) {
                    onChangeSort(sortDir === 'ASC' ? 'DESC' : 'ASC', head.key);
                  }
                }}
              >
                {head.name}
                {head.sortable && (
                  <div className="table-page-sort">
                    <i
                      className={`fa fa-angle-up ${sortColumn === head.key && sortDir === 'DESC' ? 'active' : ''}`}
                    />
                    <i
                      className={`fa fa-angle-down ${sortColumn === head.key && sortDir === 'ASC' ? 'active' : ''}`}
                    />
                  </div>
                )}
              </th>
            );
          })}
          <th style={{ width: '10px' }} />
        </tr>
        {values.map(row => {
          return (
            <tr>
              {config.heads.map(head => {
                if (head.type === 'button') {
                  const actionName = head?.options?.action;

                  return (
                    <td>
                      <TableButton
                        action={actionName !== undefined ? getAction(actionName) : undefined}
                        options={head?.options}
                        isLoading={
                          !!(
                            actionLoadings &&
                            actionName &&
                            actionLoadings[actionName] &&
                            actionLoadings[actionName].indexOf(row['id']) !== -1
                          )
                        }
                        row={row}
                        onAction={onAction}
                      />
                    </td>
                  );
                }

                if (head.type === 'checkbox') {
                  return (
                    <td>
                      <label className="table-page-checkbox">
                        <input
                          type="checkbox"
                          checked={selectedElements?.indexOf(row[head.key]) !== -1}
                          onChange={event => selectElement(row[head.key])}
                        />
                        <div className="table-page-checkbox-checkmark">
                          <div className="table-page-checkbox-checkmark-inner" />
                        </div>
                      </label>
                    </td>
                  );
                }

                if (head.type === 'link') {
                  return (
                    <td>
                      <Link
                        to={
                          head?.options?.href !== undefined
                            ? `${head?.options?.href}${row['id']}`
                            : ''
                        }
                        className="table-page-text pointer"
                      >
                        {row[head.key]}
                      </Link>
                    </td>
                  );
                }

                if (head.type === 'string') {
                  return (
                    <td>
                      <span className="table-page-text">{row[head.key]}</span>
                    </td>
                  );
                }

                if (head.type === 'date') {
                  return (
                    <td>
                      <span className="table-page-text">
                        {head.options?.format && formatDate(row[head.key], head.options.format)}
                      </span>
                    </td>
                  );
                }

                if (head.type === 'image') {
                  return (
                    <td>
                      <img src="/images/avatar.svg" alt="" className="table-page-avatar" />
                    </td>
                  );
                }
                if (head.type === 'status') {
                  return (
                    <td>
                      <span className="table-page-status">
                        <span
                          className={`table-page-status-circle ${head.options && head.options.circle[row[head.key]]}`}
                        />
                        {head.options && head.options.label[row[head.key]]}
                      </span>
                    </td>
                  );
                }

                return <td />;
              })}
              <td />
            </tr>
          );
        })}
      </table>
    </div>
  );
}

function TableButton({
  action,
  options,
  onAction,
  isLoading,
  row,
}: {
  action?: TableAction | undefined;
  options: TableFieldOptions | undefined;
  isLoading: boolean;
  onAction?: (action: TableAction, ids: number[]) => void;
  row: any;
}) {
  if (options?.complete?.flag && options?.complete?.text && row[options?.complete?.flag]) {
    return (
      <td>
        <span className="table-page-text">{options?.complete?.text}</span>
      </td>
    );
  }

  return (
    <button
      onClick={() => {
        action && onAction && onAction(action, [row['id']]);
      }}
      disabled={isLoading}
      className={`table-page-solid-button ${isLoading && 'loading'}`}
    >
      {isLoading && <span className="spinner" />}
      <i className="fa fa-envelope" />
      {options?.label}
    </button>
  );
}

export default Table;
