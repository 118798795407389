import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useOnClickOutside } from 'usehooks-ts';
import { useSelector } from 'react-redux';

type MobileMenuProps = {
  links: any[];
  onOpenSignInModal?: () => void;
  onOpenSignOutModal?: () => void;
};

function MobileMenu({ links, onOpenSignInModal, onOpenSignOutModal }: MobileMenuProps) {
  const user = useSelector(state => state.user);
  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  const handleClickOutside = () => {
    setOpen(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  const closeMenu = () => {
    setOpen(false);
  };

  if (open) {
    return (
      <div className="mobile-menu" ref={ref}>
        <div
          className="mobile-menu-close"
          onClick={() => {
            setOpen(false);
          }}
        >
          <img src="/images/menu-bar.svg" alt="" className="mobile-menu-close-icon" />
        </div>
        <div className="mobile-menu-top" />
        <ul className="mobile-menu-links">
          {links.map(link => {
            return (
              <li>
                <NavLink to={link.link} onClick={closeMenu}>
                  {link.label}
                </NavLink>
              </li>
            );
          })}
          <li style={{ marginTop: '24px' }}>
            <NavLink onClick={closeMenu} to="/privacy_policy">
              Privacy Policy
            </NavLink>
          </li>
          <li>
            <NavLink onClick={closeMenu} to="/terms_of_services">
              Terms of Services
            </NavLink>
          </li>
          <li>
            <NavLink onClick={closeMenu} to="/agreement">
              Agreement
            </NavLink>
          </li>
        </ul>
        {user.isLoggedIn ? (
          <div className="mobile-menu-buttons">
            <NavLink onClick={closeMenu} to="/video/upload" className="mobile-menu-register-button">
              Upload video
            </NavLink>
          </div>
        ) : (
          <div className="mobile-menu-buttons">
            <button
              onClick={() => {
                setOpen(false);
                onOpenSignInModal && onOpenSignInModal();
              }}
              className="mobile-menu-auth-button"
            >
              Login
            </button>
            <button
              onClick={() => {
                setOpen(false);
                onOpenSignOutModal && onOpenSignOutModal();
              }}
              className="mobile-menu-register-button"
            >
              Register
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className="site-header-menu-bar"
      onClick={() => {
        setOpen(true);
      }}
    >
      <img src="/images/menu-bar.svg" alt="" className="site-header-menu-bar-icon" />
    </div>
  );
}

export default MobileMenu;
