import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import { useSelector } from 'react-redux';
import NoAccess from '../pages/noaccess/NoAccess';
import LoginModal from './modals/LoginModal';
import RegisterModal from './modals/RegisterModal';
import ForgotPasswordModal from './modals/ForgotPasswordModal';

function Panel() {
  const user = useSelector(state => state.user.user);

  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [signOutModalOpen, setSignOutModalOpen] = useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);

  const openSignInModal = () => {
    setSignInModalOpen(true);
  };

  const openSignUpModal = () => {
    setSignOutModalOpen(true);
  };

  const openForgotPasswordModal = () => {
    setForgotPasswordModalOpen(true);
  };

  return (
    <>
      <LoginModal
        open={signInModalOpen}
        onClose={() => {
          setSignInModalOpen(false);
        }}
        onOpenForgotPasswordModal={() => {
          setSignInModalOpen(false);
          openForgotPasswordModal();
        }}
      />
      <RegisterModal
        open={signOutModalOpen}
        onClose={() => {
          setSignOutModalOpen(false);
        }}
      />
      <ForgotPasswordModal
        open={forgotPasswordModalOpen}
        onClose={() => {
          setForgotPasswordModalOpen(false);
        }}
      />
      <div className="site-header fixed">
        <Header onOpenSignInModal={openSignInModal} onOpenSignOutModal={openSignUpModal} />
      </div>
      <div className="site-page">
        <div className="site-page-container">
          {user?.role !== 'admin' ? <NoAccess /> : <Outlet />}
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Panel;
