import { API_URL } from '../core/common';
import axios from 'axios';
import { TableLoadElementsParams } from '../components/table/TablePage';
import { Location } from '../components/ui/LocationSelect';
import moment from 'moment';

export type ClientsType = {
  name: string;
  organization: string;
  email: string;
  phone: string;
  status: string;
  created_at: string;
};

export const apiClientRegister = (
  token: string,
  {
    firstName,
    lastName,
    phone,
    country,
    position,
    organization,
  }: {
    firstName: string;
    lastName: string;
    phone: string;
    country: string;
    position: string;
    organization: string;
  }
): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + '/client.register',
        {
          first_name: firstName,
          last_name: lastName,
          phone,
          country,
          position,
          organization,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiClientsGet = ({
  cancelToken,
  token,
  search,
  filters,
  offset,
}: TableLoadElementsParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL + `/clients.get?offset=${offset}&limit=${100}&search=${search}&filters=${filters}`,
        {
          cancelToken,
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error);
        }
      });
  });
};

export function apiClientsSearch(token: string, cancelToken: any, input: string) {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .get(API_URL + `/clients.search?text=${input}`, {
        cancelToken,
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data.clients);
      })
      .catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error);
        }
      });
  });
}

export const apiClientTaskCreate = (
  token: string,
  { title, description, location, deadline, contact_phone, contact_name, budget, types }: any
): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + '/task.create',
        {
          title,
          description,
          location_text: location?.location_text,
          location_place_id: location?.location_place_id,
          location_lat: location?.location_lat,
          location_lng: location?.location_lng,
          location_country: location?.location_country,
          location_state: location?.location_state,
          location_city: location?.location_city,
          deadline: deadline === null ? null : moment(deadline).format('YYYY-MM-DD'),
          contact_phone,
          contact_name,
          budget,
          types: types.join(','),
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
