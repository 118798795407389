import axios from 'axios';
import { API_URL } from '../core/common';

export const apiTransactionCreate = (token: string, video_id: string, amount: number): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + '/transaction.create',
        {
          video_id,
          value: amount,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTransactionDelete = (token: string, transaction_id: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        API_URL + '/transaction.delete',
        {
          transaction_id,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const apiTransactionsGetFromVideoId = (token: string, video_id: string): any => {
  return new Promise((resolve, reject) => {
    axios
      .get(API_URL + `/transactions.get_from_video_id?video_id=${video_id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data.transactions);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
