import React, { useEffect, useState } from 'react';
import {
  apiPartnerDeleteVideo,
  apiPartnerSendVideo,
  apiPartnersGet,
  apiPartnersSendVideo,
} from '../../../../../actions/partnesActions';
import { useSelector } from 'react-redux';
import Checkbox from '../../../../../components/ui/Checkbox';

function Partners({
  videoId,
  disabled,
  onSave,
}: {
  videoId: string;
  disabled: boolean;
  onSave: () => void;
}) {
  const token = useSelector(state => state.user.token);

  const [partners, setPartners] = useState<any>([]);
  const [selected, setSelected] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadPartners(true);
  }, []);

  const loadPartners = async (init: boolean = false) => {
    setLoading(true);
    const response = await apiPartnersGet(token, videoId);
    setPartners(response.partners);
    if (init) {
      setSelected([
        ...response.partners
          .filter((partner: any) => response.selected_exceptions.indexOf(partner.id) === -1)
          .map((partner: any) => partner.id),
      ]);
    }
    setLoading(false);
  };

  const sendAll = async () => {
    setLoading(true);
    await apiPartnersSendVideo(token, selected, videoId);
    setSelected([]);
    loadPartners();
  };

  const onChangeSelected = (partnerId: number) => {
    let _selected = selected;
    if (_selected.indexOf(partnerId) === -1) {
      _selected.push(partnerId);
    } else {
      _selected = _selected.filter(id => partnerId !== id);
    }
    setSelected([..._selected]);
  };

  return (
    <div className="video-page-partners">
      {loading ? (
        <div className="video-page-partners-loader">
          <div className="blue-spinner" />
        </div>
      ) : (
        <>
          <div className="video-page-block-top">
            {!disabled && partners.length > 0 && (
              <Checkbox
                label={''}
                checked={partners.length > 0 && partners.length === selected.length}
                onChange={() => {
                  if (partners.length === selected.length) {
                    setSelected([]);
                  } else {
                    setSelected(partners.map((partner: any) => partner.id));
                  }
                }}
              />
            )}
            <span className="video-page-block-title">Partners</span>
            {partners.length > 0 && selected.length > 0 && (
              <button className={`video-page-partners-element-button`} onClick={sendAll}>
                <i className="fa fa-send" />
                Send selected
              </button>
            )}
          </div>
          {disabled ? (
            <div className="video-page-partners-empty">
              <span className="video-page-partners-empty-text">
                Not enough data to send to partners
              </span>
            </div>
          ) : (
            <div className="video-page-partners-elements">
              {partners.map((partner: any) => {
                return (
                  <Partner
                    partner={partner}
                    videoId={videoId}
                    onSave={onSave}
                    selected={selected.indexOf(partner.id) !== -1}
                    onChangeSelected={() => {
                      onChangeSelected(partner.id);
                    }}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}

function Partner({ partner, videoId, onSave, selected, onChangeSelected }: any) {
  const token = useSelector(state => state.user.token);

  const [loading, setLoading] = useState(false);
  const [isSend, setIsSend] = useState(false);

  useEffect(() => {
    setIsSend(partner.isSend);
  }, []);

  const send = () => {
    setLoading(true);

    onSave();
    apiPartnerSendVideo(token, partner.id, videoId)
      .then(() => {
        setLoading(false);
        setIsSend(true);
      })
      .catch(() => {});
  };

  const remove = () => {
    setLoading(true);

    apiPartnerDeleteVideo(token, partner.id, videoId)
      .then(() => {
        setLoading(false);
        setIsSend(false);
      })
      .catch(() => {});
  };

  return (
    <div className="video-page-partners-element">
      <div className="video-page-partners-element-top">
        <Checkbox label={''} checked={selected} onChange={onChangeSelected} />
        <span className="video-page-partners-element-name">{partner.name}</span>
        {!isSend ? (
          <button
            className={`video-page-partners-element-button ${loading ? 'loading' : ''}`}
            disabled={loading}
            onClick={send}
          >
            {loading && <span className="spinner" />}
            <i className="fa fa-send" />
            Send
          </button>
        ) : (
          <button
            className={`video-page-partners-element-button red ${loading ? 'loading' : ''}`}
            disabled={loading}
            onClick={remove}
          >
            {loading && <span className="spinner" />}
            <i className="fa fa-trash" />
            Remove
          </button>
        )}
      </div>
    </div>
  );
}

export default Partners;
