import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { formCheckErrors, formGenerateErrorsObject } from '../../../../core/form';
import Modal from '../../../../components/ui/Modal';
import Button from '../../../../components/ui/Button';
import { useNavigate } from 'react-router-dom';
import { renderField } from '../../../../fields';
import { employeeFields } from '../../../../fields/employee';
import { apiEmployeeCreate } from '../../../../actions/employeeAction';

const errorsList = {
  firstName: 'required',
  lastName: 'required',
  position: 'required',
  email: 'required',
  password: 'required',
  phone: 'required',
};

const initialValues = {
  firstName: '',
  lastName: '',
  position: '',
  email: '',
  phone: '',
  password: '',
};

function EmployeeCreateModal({ onClose, onUpdate, open }: any) {
  const token = useSelector(state => state.user.token);

  const navigate = useNavigate();

  const [values, setValues] = useState<any>(initialValues);
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList));
  const [submitLoading, setSubmitLoading] = useState(false);

  const getFieldActions = (key: string) => {
    return {
      value: values[key],
      error: errors[key],
      onChange: (value: any) => {
        setValues({
          ...values,
          [key]: value,
        });
        setErrors({
          ...errors,
          [key]: '',
        });
      },
    };
  };

  const submit = async () => {
    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({ ...errorResponse.errors });
      return;
    }

    setSubmitLoading(true);
    const response = await apiEmployeeCreate({
      token,
      ...values,
    });
    if (response.status) {
      onUpdate();
      return;
    }
    setErrors({
      email: response.error,
    });
    setSubmitLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={
        <div className="modal-window">
          <div className="modal-window-top">
            <span className="modal-window-top-title">Create employee</span>
            <div className="modal-window-top-close" onClick={() => onClose()} />
          </div>
          <div className="modal-window-content">
            <div className="modal-window-content-inner">
              <div className="modal-window-row row">
                <div className="modal-window-col">
                  {renderField({
                    ...employeeFields.firstName,
                    ...getFieldActions('firstName'),
                    required: true,
                  })}
                </div>
                <div className="modal-window-col">
                  {renderField({
                    ...employeeFields.lastName,
                    ...getFieldActions('lastName'),
                    required: true,
                  })}
                </div>
              </div>
              <div className="modal-window-row row">
                <div className="modal-window-col">
                  {renderField({
                    ...employeeFields.phone,
                    ...getFieldActions('phone'),
                    required: true,
                  })}
                </div>
                <div className="modal-window-col">
                  {renderField({
                    ...employeeFields.email,
                    ...getFieldActions('email'),
                    required: true,
                  })}
                </div>
              </div>
              <div className="modal-window-row row">
                <div className="modal-window-col">
                  {renderField({
                    ...employeeFields.position,
                    ...getFieldActions('position'),
                    required: true,
                  })}
                </div>
                <div className="modal-window-col">
                  {renderField({
                    ...employeeFields.password,
                    ...getFieldActions('password'),
                    required: true,
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-window-bottom">
            <div className="modal-window-bottom-buttons">
              <button className="modal-window-bottom-cancel-button" onClick={onClose}>
                Cancel
              </button>
              <Button
                className={'modal-window-bottom-submit-button'}
                label={'Save'}
                loading={submitLoading}
                onClick={submit}
                type={'button'}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default EmployeeCreateModal;
