import React, { useEffect, useState } from 'react';
import { apiBriefGetCountries } from '../../../actions/briefActions';
import { apiTaskGetTypes } from '../../../actions/taskActions';

const showFilter = false;

function TasksFilters({ filters, onChange }: any) {
  const [types, setTypes] = useState<any>([]);

  useEffect(() => {
    loadTypes();
  }, []);

  const loadTypes = async () => {
    const response = await apiTaskGetTypes();
    setTypes(response);
  };

  const checkFilter = (id: string, category: any) => {
    return (
      filters.find((filter: any) => filter.id === id && filter.value === category) !== undefined
    );
  };

  const selectType = (country: any) => {
    if (checkFilter('Type', country)) {
      deleteFilter('Type', country);
    } else {
      onChange([
        ...filters,
        {
          id: 'Type',
          label: 'Type',
          value: country,
        },
      ]);
    }
  };

  const deleteFilter = (id: string, value: string) => {
    onChange(
      filters.filter(
        (filter: any) => (filter.value !== value && filter.id === id) || filter.id !== id
      )
    );
  };

  return (
    <div className="gallery-videos-left-inner">
      {types.length > 0 && (
        <div className="gallery-filter-area">
          <span className="gallery-filter-area-title">Types of work</span>
          <div className="gallery-filter-tips">
            {types.map((type: any) => {
              return (
                <span
                  onClick={() => {
                    selectType(type);
                  }}
                  className={`gallery-filter-tip ${checkFilter('Type', type) && 'active'}`}
                >
                  {type}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export default TasksFilters;
