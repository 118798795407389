import React from 'react';

function Terms() {
  return (
    <div className="custom-page">
      <div className="custom-page-container">
        <h1 className="custom-page-title">Stringershub Terms of Service</h1>

        <div className="custom-page-content">
          <div className="custom-page-content-text">
            <p>
              PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY USING THE SITE YOU AGREE THAT THESE
              TERMS OF SERVICE CONSTITUTE A LEGAL AGREEMENT (THE &ldquo;AGREEMENT&rdquo; OR
              &ldquo;TERMS OF SERVICE&rdquo;) BETWEEN YOU AND STRINGERSHUB. IF YOU DO NOT AGREE TO
              THESE TERMS, PLEASE DO NOT ACCESS THE SITE. IF YOU ARE USING THE SITE ON BEHALF OF
              YOUR EMPLOYER, PRINCIPAL OR CLIENT, EACH OF YOU AND YOUR EMPLOYER, PRINCIPAL OR CLIENT
              ARE SUBJECT TO THESE TERMS AND CONDITIONS, AS AMENDED FROM TIME TO TIME.
            </p>

            <p>
              These Terms of Service apply to the website (the &ldquo;Site&rdquo;) operated by
              StringersHub (&ldquo;StringersHub&rdquo; or &ldquo;We&rdquo;), including all means by
              which StringersHub provides content or services from the Site to you, such as
              accessing the Site online and delivery of content from the Site to you at your request
              (the &ldquo;Services&rdquo;).
            </p>

            <p>
              StringersHub is an online marketplace where users can buy, license or sell video or
              photograph news content (the &ldquo;Content&rdquo;). These Terms and Conditions apply
              whether you are seeking Content to use/license (&ldquo;Content Consumer&rdquo;, under
              Section 1) or you are seeking to sell or otherwise monetize your own Content
              (&ldquo;Content Providers&rdquo; or &ldquo;Stringers&rdquo;, under Section 2).
              StringersHub does not buy, sell or license Content, except for the limited purposes
              expressly set forth in this Agreement. StringersHub makes no guarantees regarding
              either the Content available or the subsequent use of such Content by Content
              Consumers.
            </p>

            <p>
              All transactions on StringersHub will be governed by some form of Content License
              Agreement (as defined below), and these Terms and Conditions apply in addition to your
              obligations under any applicable Content License Agreement that you enter into. In the
              event of any conflict between this Agreement and any applicable Content License
              Agreement, the Content License Agreement will control as to the conflicting terms. You
              may also be required to register to access certain portions of the Site or certain
              Content, and there may be additional requirements imposed on you as a result of such
              registration.
            </p>

            <p>
              StringersHub may modify these Terms of Service from time to time in its sole
              discretion. Each time you use or access the Site or Services or Content from the Site
              or Services you will be deemed to have accepted any changes to these Terms of Service.
              StringersHub shall have no obligation to notify you of any such changes other than
              posting such modifications on the Site.
            </p>

            <p>Last Modified: May 14, 2018</p>

            <p></p>

            <p>
              SECTION 1. CONTENT CONSUMERS In addition to the other sections of this Agreement, by
              downloading, licensing or otherwise using any Content found on the Site, you
              acknowledge and agree to the following terms:
            </p>

            <p>
              Limited License for Screener Usage. Conditioned on your compliance with this Agreement
              and registration with the Site, StringersHub grants you a limited license to view or
              download watermarked Content solely for evaluating whether you wish to purchase a
              license to the image or video (&ldquo;Unpurchased Content&rdquo;) via the StringersHub
              platform. You may access Unpurchased Content solely to determine whether you intend to
              obtain a license for the Content. You may not use Unpurchased Content in any final
              materials distributed within your company or any materials distributed outside of your
              company or to the public or in any online or other electronic distribution system.
              Except for this limited, internal testing and sample use, you are granted no other
              rights, and you may not copy, distribute, publish, display, sublicense, make
              available, or otherwise use in any way the Unpurchased Content outside the scope of
              this Agreement. Licensing of Content. In order to download, license or use copyrighted
              Content from the Site, you must enter into and agree to be bound by the terms and
              conditions set forth in our Standard Content License Agreement (found in Section 4
              hereto) or other applicable agreements with us for your use of Content prior to the
              delivery or download of such Content (any such agreement, whether for license or sale
              of rights, will be referred to herein as a &ldquo;Content License Agreement&rdquo;).
              In the event you receive or download any Content without a valid Content License
              Agreement, you must return the Content to StringersHub, make no public use of the
              Content, and retain no copies of the Content. In the event you make any commercial,
              editorial or public use of any Content without a valid Content License Agreement, your
              use shall constitute copyright infringement and entitle StringersHub to all legal and
              equitable relief against you for such use, and you shall indemnify StringersHub, its
              licensors and content providers, and their respective officers, directors, employees,
              and agents against all claims, damages, liabilities, costs and expenses, including
              reasonable attorneys&rsquo; fees, arising from or in connection with your use or
              exhibition of such Content, and for your negligent or willful misconduct. StringersHub
              in its sole discretion reserves the right to bill you (and you hereby agree to pay)
              ten (10) times the license fee for any unauthorized use, in addition to any other
              fees, damages and penalties StringersHub may be entitled to under this Agreement, any
              applicable Content License Agreement and applicable law. The foregoing is not a
              limiting statement of StringersHub&rsquo;s or its Content sources&rsquo; rights or
              remedies in connection with any unauthorized use of the Content or breach of this
              Agreement or an applicable Content License Agreement. Non-Newsmaking Commercial Use
              Warranty Disclaimer. Content at StringersHub is intended for bona fide news and
              information purposes and not intended for purely non-newsmaking commercial purposes.
              The people, property and other images within our Content was obtained for news and
              information purposes, not purely sales or non-newsmaking commercial use. If a Content
              Consumer wishes to use Content for purely commercial purposes (purposes other than
              news production, academic/educational content creation, or artistic creation),
              StringersHub does not warrant copyright free content and does not warrant that
              releases have been obtained from anyone. You acknowledge that no releases are
              generally obtained for Content, and that some jurisdictions provide legal protection
              against a person's image, likeness or property being used for non-newsmaking
              commercial purposes when they have not provided a release. Labeling and Metadata
              Disclaimer. While we have made reasonable efforts to correctly categorize, keyword,
              label and title the Content, StringersHub does not warrant the accuracy of such
              information or any metadata provided with the content. No Warranties. All Content is
              provided &quot;as is&quot; without representation, warranty or condition of any kind,
              either express or implied, including, but not limited to, implied representations,
              warranties or conditions of merchantability, or fitness for a particular purpose.
              StringersHub does not represent or warrant that the Content will meet your
              requirements or that its use will be uninterrupted or error free. SECTION 2. CONTENT
              PROVIDERS (STRINGERS): In addition to the other sections of this Agreement, by
              uploading, posting, linking or otherwise promoting any Content on the Site, you
              acknowledge and agree to the following terms:
            </p>

            <p>
              You will only upload, post or link Content (videos and images) that you own or have
              received the express rights to upload. If you have received the rights to upload, sell
              or otherwise distribute Content from another party, you agree that you will only
              upload, post or link that Content if you know and can verify the original author of
              the Content (the natural person who operated the camera or other recording device at
              the time the Content was captured). You may be required to designate your content as
              available for exclusive or non-exclusive purchase or license. In doing so, you warrant
              that such designations are accurate and truthful. You are solely responsible for any
              resulting claims or damages due to your misrepresentation of content as exclusive. You
              agree that any Content you upload on the Site as available for licensure in
              StringersHub&rsquo;s Gallery is thereby licensed to StringersHub for the purpose of
              offering your Content for license or sale. To effectuate its purpose of promoting the
              Content for license or sale, StringersHub may share your Content (partially or in its
              entirety) with third parties, in StringersHub&rsquo;s sole discretion. You further
              agree that at any time and without prior notice to you, StringersHub may enter into a
              binding contract on your behalf to license your Content in accordance with
              StringersHub&rsquo;s fee structure and Standard Content License Agreement (found in
              Section 4 hereto). You further agree that StringersHub may act as your agent in
              negotiating any Non-Standard Agreement, although StringersHub will obtain your prior
              consent before entering into any Agreement on your behalf. YOU AGREE AND ACKNOWLEDGE
              THAT STRINGERSHUB IS NOT RESPONSIBLE FOR THE OUTCOME OF ANY TRANSACTIONS OR
              NEGOTIATIONS OR PROMOTIONS UNDERTAKEN WITH THE PURPOSE OF LICENSING OR SELLING YOUR
              CONTENT, INCLUDING INFRINGEMENT, DISPUTES, LIABILITIES. STRINGERSHUB DISCLAIMS ALL
              SUCH LIABILITY TO THE FULLEST EXTENT PERMITTED BY LAW. Safety and Security.
              StringersHub does not endorse, and will not accept or allow distribution of content
              that: a) is captured in a manner that endangers the Content Provider or any other
              person; b) violates the privacy rights or right of publicity of any individual, as
              provided by applicable law and regulation; c) violates any person&rsquo;s intellectual
              property rights; d) violates any other applicable law or regulation. By using the
              Site, you agree not to upload any Content that violates this provision. Your
              Relationship with StringersHub. You hereby agree and acknowledge that you are not (nor
              shall you be deemed to be) an employee, agent, partner, joint venture affiliate, or
              representative of StringersHub. You will use the highest quality videos and images as
              available to you, to maintain a reasonable level of quality, solely determined by
              StringersHub. Rights Reserved. You agree and acknowledge that: a) StringersHub has the
              right to reject and unpublish any content you publish; b) the right to edit any
              uploaded or linked content and/or posted text, at its own discretion. Disclaimer: IN
              ADDITION TO THE DISCLAIMERS AND LIMITATIONS ON LIABILITY APPLYING TO ALL USERS OF THE
              SITE, YOU SPECIFICALLY AGREE THAT STRINGERSHUB IS NOT RESPONSIBLE FOR ANY DAMAGES
              SUSTAINED AS A RESULT OF YOUR USE OF THE SITE AND SERVICES, INCLUDING YOUR EFFORTS TO
              OBTAIN CONTENT. STRINGERSHUB DISCLAIMS ANY SUCH LIABILITY TO THE FULLEST EXTENT
              PERMISSIBLE BY LAW. SECTION 3. NON-CIRCUMVENTION Agreement Not to Circumvent
              StringersHub. You acknowledge and agree that the compensation StringersHub receives
              for making the Site available to you is collected through the standard fees or markups
              on your listed Content and related services. StringersHub only receives this income
              when a Content Buyer and a Content Seller pay and receive payment through the
              StringersHub. Therefore, for 12 months from the time you identify or are identified by
              any party through the Site (the &ldquo;Non-Circumvention Period&rdquo;), you must use
              the Site as your exclusive method to sell Content to, or buy Content from, (and make
              related payments to, or receive related payments from) that party. You recognize and
              agree that StringersHub would be materially damaged by any violations of this
              provision. If you do not agree to the terms of this provision, please do not use the
              Site. SECTION 4. STANDARD CONTENT LICENSE AGREEMENT The following Standard Content
              License Agreement (Subsection 4.1) shall apply to any content &ldquo;purchased&rdquo;
              or &ldquo;bought&rdquo; on the Site by paying a fee and downloading Content from the
              Gallery section of the site, absent a specifically-negotiated agreement to license or
              sell Content via the Site or Services (a &ldquo;Non-Standard Agreement&rdquo;). This
              Standard Content License Agreement shall not apply to any Content specifically
              commissioned via StringersHub (in other words, Content specifically requested by
              Content Consumers and not found in the Gallery). In the event that such a Non-Standard
              Agreement should be entered into, the terms of that Non-Standard Agreement shall apply
              and entirely supersede those of the Standard Content License Agreement, provided that
              such Non-Standard Agreement has been negotiated with the express written consent of
              StringersHub. Notwithstanding the foregoing, the parties to a Non-Standard Agreement
              shall still be subject to all other terms of this Agreement (Sections 1, 2, 3 and 5 of
              StringersHubs&rsquo; Terms of Service).
            </p>

            <p>
              This Standard Content License Agreement (&ldquo;License&rdquo;) between the Content
              Buyer (the licensee of content), and Content Seller (the licensor of content),
              establishes the terms and conditions for the Content Buyer&rsquo;s use of photos and
              video clips that are downloaded from StringersHub. In consideration for the listed
              license fee for Content purchased by the Content Buyer (&ldquo;Licensed
              Content&rdquo;), the parties agree as follows: Grant of Rights: Upon receipt of
              payment of the listed price for Licensed Content, Content Buyer is hereby granted the
              perpetual, non-exclusive, right and license to reproduce, publish, display and use the
              Licensed Content. This license is perpetual: absent breach of this License or
              termination by either party, there is no expiration or end date on Content
              Buyer&rsquo;s license to use the Licensed Content. This License is non-exclusive:
              Content Buyer does not have exclusive rights to use the Licensed Content. Content
              Seller and StringersHub can license the same content to other buyers. This License is
              royalty-free: Content Buyer can use the Licensed Content in an unlimited number of
              projects and in any media. For purposes of this License, &quot;use&quot; means to
              copy, reproduce, modify, edit, synchronize, perform, display, broadcast, publish, or
              otherwise make use of. This License is subject to the various restrictions herein.
              Except as expressly stated herein, Content Buyer shall acquire no right in the
              Licensed Content by virtue of this License. Content Buyer will not undertake to obtain
              copyright, trademark, service mark, trade secret, patent rights or other intellectual
              property rights with respect to the Licensed Content and any Intellectual Property
              therein. Transfer and Sublicensing. The rights granted to Content Buyer are
              transferable and sub-licensable solely for transferees and sub-licensees who use the
              Content in accordance with this License. Restricted Uses. Content Buyer may use
              content in any way that is not restricted. The following uses are restricted: (1)
              Content Buyer may not use content in a pornographic, defamatory or other unlawful
              manner; (2) Content Buyer may not use content as part of a trademark, design mark,
              tradename, business name, service mark, or logo; (3) Content Buyer or any affiliated
              licensees may not falsely represent that they are the original creator of a work that
              is made up largely of Licensed Content; (4) If Licensed Content is used in connection
              with a subject that a reasonable person would find politically provocative,
              unflattering or unduly controversial (for example, sexually transmitted diseases or
              criminal conduct), Content Buyer must indicate: (a) that the content is being used for
              illustrative purposes only, and (b) any person depicted in the Licensed Content is not
              connected with the topic reported. Ownership. All of the Licensed Content is owned by
              either StringersHub or the users who supply the Licensed Content. All rights not
              expressly granted in this License are reserved by StringersHub and the Content Seller.
              Termination. This License is effective until it is terminated by either party. Content
              Buyer may terminate this License by notifying StringersHub in writing, ceasing use of
              the Licensed Content and deleting or destroying any unused video downloads.
              StringersHub may terminate this License at any time if Content Buyer fails to comply
              with any of the terms, in which case Content Buyer must immediately: cease downloading
              the Licensed Content; delete or destroy any unused video downloads; and, if requested,
              confirm to StringersHub in writing that they have complied with these requirements.
              StringersHub may discontinue licensing any item of content at any time in its sole
              discretion. Upon notice from StringersHub, or upon any parties&rsquo; knowledge that
              any content may be subject to a claim of infringement of a third party's right for
              which StringersHub or Content Seller may be liable, StringersHub may require Content
              Buyer to immediately, and at Content Buyer&rsquo;s own expense: cease using the
              content, delete or destroy any copies; and ensure that Content Buyer&rsquo;s clients,
              distributors and/or employer do likewise. In such cases, StringersHub will either
              provide Content Buyer with a refund or replacement content (determined solely by
              StringersHub in its reasonable commercial judgment). SECTION 5. GENERAL TERMS
              APPLICABLE TO ALL USERS OF THE SITE The Site is owned and operated by StringersHub.
              All aspects of the Site, including, but not limited to the design and &ldquo;look and
              feel&rdquo; thereof and all of the Content featured or displayed thereon, including,
              but not limited to text, graphics, photographs, images, moving images, animation,
              sound, illustrations and software and compilations thereof are, as between you and
              StringersHub, owned by StringersHub and/or its licensors or content providers. All
              elements of the Site, including but not limited to, the Content thereof are protected
              by trade dress, copyright, moral rights, trademark and other laws relating to property
              rights. Except as explicitly permitted under separate agreement with StringersHub, no
              portion or element of the Site or the Content may be reproduced, distributed,
              modified, publicly performed or displayed, transmitted, reverse engineered,
              decompiled, sold, sublicensed or otherwise exploited via any means or in any way and
              the Site, its Content and all related rights shall remain the exclusive property of
              StringersHub and/or its Licensors and/or Content providers unless otherwise expressly
              agreed. You must be 18 years of age to be eligible to use this Site unsupervised and
              we ask that minors and children do not register for an account or submit any personal
              information to us. By using this Site and/or registering for an account, you warrant
              that you are 18 years of age or older. You are responsible for any charges incurred in
              obtaining access to the Site and the Services. Registration with the Site grants you
              certain privileges. Registration may be required to access certain Services or Content
              or to receive email alerts or other interactive or on demand Services. You agree to
              provide true, accurate and complete information about yourself, and to update this
              information when it changes. If you provide any information that is untrue or
              inaccurate, not current, or incomplete, or if StringersHub suspects that your
              information is untrue or inaccurate, not current, or incomplete, or for any other
              reason, StringersHub may, in its sole discretion, suspend or terminate your membership
              and/or access to certain Content or refuse current or future access to the Service.
              Any personal information supplied hereunder will be subject to the terms of our
              Privacy Policy. You shall be liable for payment for all Content downloaded by others
              using your account, with or without your permission and knowledge prior to the time
              that you notify StringersHub of any unauthorized use. You acknowledge and agree that
              you are responsible for damages, fees or costs resulting from all uses of your
              account, whether actually or expressly authorized by you. Privacy Policy. You confirm
              that you have read, understood and agree to the StringersHub&rsquo;s Privacy Policy,
              the terms of which are incorporated herein, and agree that the terms of such policy
              are reasonable and satisfactory to you. You consent to the use of your personal
              information by StringersHub, its third-party providers, and/or partners in accordance
              with the terms of and for the purposes set forth in the StringersHub Privacy Policy.
              Please note that the personal information you submit to the Site may be transferred
              within or outside the United States and processed by StringersHub in order to provide
              this Site and its services to you, or for such other purposes (as set forth in the
              Privacy Policy). Amendments to the Terms of Service. StringersHub reserves the right,
              at our sole discretion, to change, modify or otherwise alter this Agreement at any
              time. You agree that we may modify these Terms of Service and such modifications shall
              be effective immediately upon posting. You agree to review these terms periodically to
              be aware of modifications. Continued access or use of the Site following such posting
              shall be deemed conclusive evidence of your acceptance of the modified Agreement
              except and to the extent prohibited by applicable state or federal law. Should
              StringersHub provide to you a password that allows you to access certain parts of the
              Site or for the Services, you will be solely responsible for maintaining the
              confidentiality of the password and your account information, and are fully
              responsible for all activities that occur under your password or account. You agree
              that you will immediately notify StringersHub of any unauthorized use of your password
              or account, or any other breach of security, and that you will log off of the Site or
              the Services at the end of each session to prevent fraud on your account by third
              parties. Communications from the StringersHub. StringersHub may send you newsletters
              or other communications. You have the option to unsubscribe from such emails.
              StringersHub reserves the right to send you emails regarding your account or emails
              containing service updates or other communications relating to the operation of the
              Site or Services. Third Parties. If you use this Site to conduct business for or on
              behalf of a third party (&quot;Third Party&quot;), you are responsible for any error
              in the accuracy of information provided in connection with such use as well as for any
              transactions entered into, including related fees, charges and performance
              obligations. In addition, you must inform the Third Party of all terms and conditions
              applicable to all products or services acquired through this Site, including all rules
              and restrictions applicable thereto. You acknowledge and agree that StringersHub
              reserves the right to, and may from time to time, monitor any and all information
              transmitted or received through the Site or Services for operational and other
              purposes. During monitoring, information may be examined, recorded, copied, and used
              for authorized purposes in accordance with our Privacy Policy. Use of the Site or
              Services constitutes consent to such monitoring. However, you acknowledge and agree
              that StringersHub has no obligation to monitor the use of the Site or Services by
              members. Use of the Site and Services. You may not either directly or through the use
              of any device, software, internet site, web-based service or other means remove,
              alter, bypass, avoid, interfere with, or circumvent (i) any copyright, trademark, or
              other proprietary notices marked on the Content, (ii) any digital rights management
              mechanism, device, or other content protection or access control measure associated
              with the Content including geo-filtering mechanisms, or (iii) any advertisement
              inserted on the Site and/or within the Content. You may not either directly or through
              the use of any device, software, internet site, web-based service or other means copy,
              download, stream capture, reproduce, duplicate, archive, distribute, upload, publish,
              modify, translate, broadcast, perform, display, sell, transmit or retransmit the
              Content unless expressly permitted by StringersHub in writing. You may not incorporate
              the Content into, or stream or retransmit the Content via, any hardware or software
              application or make it available via frames or in-line links, or scrape or aggregate
              Content from the Site. You may not build a business, in whole or in part, utilizing
              any portion of the Content, whether or not for profit. The Content covered by these
              restrictions includes without limitation any text, graphics, layout, interface, logos,
              photographs, audio and video materials, and stills. In addition, you are strictly
              prohibited from creating derivative works or materials that otherwise are derived from
              or based on in any way on the Content, including montages, mash-ups and similar
              videos, wallpaper, desktop themes, greeting cards, and merchandise. This prohibition
              applies even if you intend to give away the derivative materials free of charge.
            </p>

            <p>
              To the extent that StringersHub makes Content available to you via a widget, embedded
              player, rss feed or other technology that allows you to embed or stream Content on or
              to another site, you may not modify, enhance, remove, interfere with, or otherwise
              alter in any way any portion of such technology, any digital rights management
              mechanism, device, or other content protection or access control measure associated
              with such technology, or any advertisement delivered via such technology.
            </p>

            <p>
              You are also prohibited from disabling, reverse engineering, modifying, interfering
              with or otherwise circumventing any technology to allow users to view the Content
              without: (i) displaying visibly both the Content and all surrounding elements
              (including the graphical user interface, any advertising, copyright notices, and
              trademarks); and (ii) having full access to all functionality permitting viewing of
              the Content, including, without limitation, all video quality and display
              functionality and all interactive, elective or click-through advertising
              functionality.
            </p>

            <p>
              FOR THE PROTECTION OF STRINGERSHUB AND ITS LICENSORS AND CONTENT PROVIDERS, CONTENT
              MAY BE VISIBLY, INVISIBLY, OR ELECTRONICALLY WATERMARKED AND MAY INCLUDE THE USE OF
              DIGITAL RIGHTS MANAGEMENT SYSTEM TECHNOLOGY WITHIN THE CONTENT. SUCH TECHNOLOGY MAY
              PERMIT ONLINE CRAWLING OR TRACKING OF CONTENT OBTAINED FROM STRINGERSHUB AND/OR OTHER
              METHODS OF PROTECTING, MONITORING, OR TRACKING THE UNAUTHORIZED USE OF THE CONTENT
              (&ldquo;RIGHTS MANAGEMENT SYSTEMS&rdquo; OR &ldquo;RMS&rdquo;). If you do not consent
              to StringersHub&rsquo;s use of RMS, do not use the Site or any Content found therein.
              You shall not knowingly disable any such technology or tool. You may not remove any
              copyright or other proprietary notices contained in the Content, caption information,
              or any other material on this Site. Additionally, unless authorized by StringersHub in
              writing, you may not: deep link or employ software or any automatic device, technology
              or algorithm, to &ldquo;crawl,&rdquo; &ldquo;scrape,&rdquo; search or monitor the Site
              and/or retrieve or copy Content or related information; violate the mechanical
              restrictions of the Site, or bypass other measures employed to prevent or limit access
              to the Site or Content by hacking or other means; copy, redirect, or exploit the Site
              or any Content; probe, scan, or test the vulnerability of the Site or of the network
              supporting the Site, or seek information on visitors to the Site or personal
              information of our customers; or use any device, software or routine that would
              interfere with the proper functioning of the Site, or any transaction conducted via
              the Site. YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
            </p>

            <p>
              THE SITE IS PROVIDED ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS.
              TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, STRINGERSHUB EXPRESSLY
              DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
              LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
              AND NON-INFRINGEMENT.
            </p>

            <p>
              STRINGERSHUB MAKES NO WARRANTY THAT: (i) THE SITE SHALL MEET YOUR REQUIREMENTS; (ii)
              THE SITE SHALL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR FREE OF VIRUSES OR
              OTHER HARMFUL COMPONENTS; (iii) THE CONTENT OR THE RESULTS THAT MAY BE OBTAINED FROM
              THE USE OF THE SITE SHALL BE ACCURATE OR RELIABLE; (iv) THE QUALITY OF ANY PRODUCTS,
              SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SITE
              SHALL MEET YOUR EXPECTATIONS; (v) ANY ERRORS IN THE CONTENT OR SITE SHALL BE
              CORRECTED; AND (vi) THE SITE OR ANY CONTENT IS MERCHANTABLE, FIT FOR A PARTICULAR
              PURPOSE OR DOES NOT INFRINGE UPON ANOTHER&rsquo;S RIGHTS.
            </p>

            <p>
              NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM STRINGERSHUB
              OR THROUGH OR FROM THE SITE SHALL CREATE ANY WARRANTY, UNLESS EXPRESSLY STATED IN
              THESE TERMS OF SERVICE OR IN ANY APPLICABLE AGREEMENT, BETWEEN YOU AND STRINGERSHUB.
            </p>

            <p>
              You agree that StringersHub is not responsible for any problems or technical
              malfunction of any telephone network or lines, computer online systems, servers or
              providers, computer equipment, software, failure of any email or players due to
              technical problems or traffic congestion on the Internet or on any of the Services or
              combination thereof, including any injury or damage to users or to any person's
              computer related to or resulting from participation or downloading materials in
              connection with the Services. Under no circumstances shall StringersHub be responsible
              for any loss or damage resulting from use of the Site, from any Content posted on or
              through StringersHub, or from the conduct of any users of the Services, whether online
              or offline.
            </p>

            <p>
              Despite our efforts to provide accurate information, this Site may contain technical
              or other mistakes, inaccuracies or typographical errors. Additionally, this Site and
              its Content may include historically and culturally important images and text that may
              contain subjects that reflect the social attitudes and circumstances of a particular
              time or location. You should be aware that your search may display results containing
              content that may seem inappropriate to its context, or text that could be potentially
              offensive. You are solely responsible for determining whether your use of any Content
              requires the consent of any third party or the license of any additional rights, and
              you should not rely solely on the information provided by StringersHub or its
              licensors or content providers. If you are unsure whether additional third-party
              rights are needed for your use, you are responsible for consulting with competent
              rights management professionals or legal counsel. LIMITATION OF LIABILITY. YOU
              EXPRESSLY UNDERSTAND AND AGREE THAT:
            </p>

            <p>
              NEITHER STRINGERSHUB NOR ITS LICENSORS OR CONTENT PROVIDERS SHALL BE RESPONSIBLE OR
              LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR
              PUNITIVE DAMAGES RESULTING FROM YOUR ACCESS TO OR USE OF THE SITE OR THE CONTENT
              THEREON UNLESS OTHERWISE PROVIDED IN AN APPLICABLE AGREEMENT BETWEEN YOU AND
              STRINGERSHUB. WITH RESPECT TO ALL OTHER CLAIMS RELATED TO YOUR USE OF THE SITE OR
              CONTENT, UNLESS OTHERWISE SET FORTH IN A LICENSE AGREEMENT FOR USE OF THE CONTENT WITH
              STRINGERSHUB, STRINGERSHUB&rsquo;S LIABILITY SHALL NOT EXCEED THE LESSER OF THE AMOUNT
              PAID TO STRINGERSHUB BY YOU, IF ANY, OR $500.
            </p>

            <p>
              THE LIMITATIONS, EXCLUSIONS AND DISCLAIMERS IN THIS SECTION AND ELSEWHERE IN THESE
              TERMS OF SERVICE APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
            </p>

            <p>
              You agree to defend, indemnify and hold harmless StringersHub, its parent and
              subsidiary companies and divisions, its affiliates and their respective directors,
              officers, employees and agents from and against any and all claims, actions, suits or
              proceedings, as well as any and all losses, liabilities, damages, costs and expenses
              (including reasonable attorneys fees) arising out of or accruing from your breach of
              these Terms of Service or your unauthorized use of the Site, the Content and/or other
              related rights. StringersHub may, in its sole discretion, terminate your password,
              account (or any part thereof) and/or your use of or license to the Site or Services
              (or any portion thereof), or remove and discard any information stored, sent, or
              received via the Site or Services without prior notice and for any reason, including,
              but not limited to: (i) permitting another person or entity to use your Registration
              information to access a Site or Service, (ii) any other access or use of the Site or
              Service except as expressly provided in these Terms of Service, (iii) any violation of
              the terms and conditions of these Terms of Service including the rules and regulations
              relating to the use of, the Content, Site or Services, or (iv) tampering with or
              alteration of any of the Content, Site or Services. You may terminate your
              Registration by closing your Registration account. Termination, suspension, or
              cancellation of these Terms of Service or your access rights shall not affect any
              right or relief to which StringersHub may be entitled, at law or in equity. Upon
              termination of these Terms of Service, all rights granted to you will automatically
              terminate. Infringement Policy. StringersHub respects the rights of all copyright
              holders and in this regard, StringersHub has adopted and implemented a policy that
              provides for the termination in appropriate circumstances of users and account holders
              who infringe the rights of copyright holders. If you believe that your work has been
              copied in a way that constitutes copyright infringement, please provide StringersHub's
              Copyright Agent the following information required by the Online Copyright
              Infringement Liability Limitation Act of the Digital Millennium Copyright Act, 17
              U.S.C. 512:
            </p>

            <p>
              A physical or electronic signature of a person authorized to act on behalf of the
              owner of an exclusive right that is allegedly infringed;
            </p>

            <p>
              Identification of the copyright work claimed to have been infringed, or, if multiple
              copyrighted works at a single online site are covered by a single notification, a
              representative list of such works at that site;
            </p>

            <p>
              Identification of the material that is claimed to be infringing or to be the subject
              of infringing activity and that is to be removed or access to which is to be disabled,
              and information reasonably sufficient to permit us to locate the material;
            </p>

            <p>Information reasonably sufficient to permit us to contact the complaining party;</p>

            <p>
              A statement that the complaining party has a good-faith belief that use of the
              material in the manner complained of is not authorized by the copyright owner, its
              agent, or the law; and
            </p>

            <p>
              A statement that the information in the notification is accurate, and under penalty of
              perjury, that the complaining party is authorized to act on behalf of the owner of an
              exclusive right that is allegedly infringed.
            </p>

            <p>
              For copyright inquiries under the Digital Millennium Copyright Act please contact:
            </p>

            <p> Copyright Agent STRINGERSHUB, INC</p>

            <p>446 W 14th St, New York, NY 10014, 2nd floor</p>

            <p>Email: info@stringershub.com</p>

            <p>
              {' '}
              Export Control. Unless otherwise specified, the materials on the Site and Services are
              presented solely to provide information regarding and to promote StringersHub&rsquo;s
              Services and other products available in the United States, its territories,
              possessions and protectorates. The Site and Services are controlled and operated by
              StringersHub. StringersHub makes no representation that materials on the Service are
              appropriate or available for use in every jurisdiction. Those who choose to access the
              Site and Services from outside the United States and the European Union do so on their
              own initiative and are responsible for compliance with local laws, if and to the
              extent that local laws are applicable. Software from the Site and Services are further
              subject to United States export controls. No software may be accessed, downloaded or
              otherwise exported or re-exported (A) into (or to a national or resident of) Cuba,
              Iraq, Libya, North Korea, Iran, Syria, or any other country to which the U.S. has
              embargoed goods, or (B) to anyone on the U.S. Treasury Department list of Specially
              Designated Nationals or the U.S. Commerce Department's Table of Deny Orders. By using
              the Service, you represent and warrant that you are not located in, under the control
              of, or a national or resident of any such country or on any such list. This Agreement
              shall be governed by and construed in accordance with the laws of the State of
              Delaware without giving effect to any principles of conflicts of law. Although you
              acknowledge that StringersHub has the ability to enforce our rights in any court of
              competent jurisdiction, you hereby consent to the exclusive jurisdiction and venue of
              courts in the State of Delaware, in the United States, regarding any and all disputes
              relating to these Terms of Service or your use of the Site and Services. You
              acknowledge and agree that the warranty disclaimers and liability and remedy
              limitations in these Terms of Service are material terms of these Terms of Service and
              that they have been taken into account in the decision by StringersHub to provide the
              Site and Services hereunder. You may not assign any of your rights, obligations or
              privileges hereunder without the prior, written consent of StringersHub. Any
              assignment of the foregoing other than as provided for in this section shall be null
              and void, ab initio. If any provision of this agreement shall be unlawful, void, or
              for any reason unenforceable, then that provision shall be deemed severable from these
              Terms of Service, shall be enforced to the fullest extent allowed by law as to effect
              the intention of the parties, and shall not affect the validity and enforceability of
              any remaining provisions. These Terms of Service and any posted operating rules
              constitute the entire agreement of the parties with respect to the subject matter
              hereof, and supersede all prior or contemporaneous communications and proposals,
              whether oral or written, between the parties with respect to such subject matter. No
              waiver of any provision or any right granted hereunder will be effective unless set
              forth in a written instrument signed by the waiving party. No waiver by either party
              of any breach or default hereunder shall be deemed a waiver of any subsequent breach
              or default. You agree not to reproduce, duplicate, copy, sell, resell, or exploit for
              any commercial purposes, any portion of the Service or access to the Service. The
              titles and subtitles used in these Terms of Service are used for convenience only and
              are not to be considered in construing or interpreting these Terms of Service.
            </p>
          </div>
          <div className="custom-page-image">
            <img src="/images/operator.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Terms;
