import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { convertFilters } from '../../core/helpers';
import axios from 'axios';
import TableSearchField from './TableSearchField';
import ReactPaginate from 'react-paginate';
import TableActions from './TableActions';
import TableFilters from './TableFilters';
import Table from './Table';
import TableSwitcher from './TableSwitcher';
import moment from 'moment';
import { API_URL } from '../../core/common';

export type TableAction = {
  label: string;
  name: string;
  icon?: 'send' | 'delete' | 'user';
  single?: boolean;
  function?: (ids: number[]) => void;
  successCallback?: (ids: number[], elements: any[]) => any[];
};

export type TableFieldOptions = {
  label?: any;
  format?: string;
  circle?: any;
  href?: any;
  action?: string;
  complete?: {
    text: string;
    flag: string;
  };
};

export type TableHead = {
  name?: string;
  width?: string | null;
  key: string;
  type?: string;
  options?: TableFieldOptions;
  sortable?: boolean;
};

export type TableActionLoadings = {
  [key: string]: any[];
};

export type TableFilter = {
  id: string;
  type: 'search' | 'select' | 'date' | 'input' | 'textarea';
  label: string;
  options?: any;
};

export type TablePreset = {
  label: string;
  filters: {
    id: string;
    label: string;
    value: string;
  }[];
};

export type TableAggregator = {
  label: string;
  column: string;
};

export type TableLoadElementsParams = {
  cancelToken: any;
  token: string;
  search: string;
  filters: string;
  offset: number;
  limit?: number;
  user_id?: string;
  sortDir: string;
  sortColumn: string;
};

export type TableConfig = {
  title: string;
  key: string;
  heads: TableHead[];
  sort?: {
    dir: 'ASC' | 'DESC';
    column: 'created_at';
  };
  functions: {
    loadElementsMethod: (params: TableLoadElementsParams) => Promise<any>;
    convertElementsMethod: ((elements: any[]) => void) | null;
  };
  options: {
    hasSearch: boolean;
    hasSwitcher: boolean;
    createButton?: {
      label: string;
      link?: string;
      action?: () => void;
    };
    exportButton?: {
      link: string;
    };
    blockView?: {
      obj: (obj: any) => ReactElement;
      className: string;
    };
  };
  actions?: TableAction[];
  filters?: TableFilter[];
  presets?: TablePreset[];
  aggregators?: TableAggregator[];
};

type TablePageType = {
  config: TableConfig;
  initialFilters?: any;
};

const CancelToken = axios.CancelToken;
let source: any;

function TablePage({ config, initialFilters = [] }: TablePageType) {
  const [_searchParams, setSearchParams] = useSearchParams();
  const token = useSelector(state => state.user.token);

  const [elements, setElements] = useState<any>([]);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');

  const [actionLoadings, setActionLoadings] = useState<TableActionLoadings>({});

  const [filters, setFilters] = useState<any>(initialFilters);
  const [loading, setLoading] = useState(false);
  const [selectedElements, setSelectedElements] = useState<any>([]);

  const [aggregators, setAggregators] = useState<Record<string, string>>({});

  const [type, setType] = useState('list');

  const [sortColumn, setSortColumn] = useState<string>(config.sort ? config.sort.column : '');
  const [sortDir, setSortDir] = useState<'ASC' | 'DESC'>(config.sort ? config.sort.dir : 'DESC');

  useEffect(() => {
    setActionLoadings(generateActionsLoadings());

    const result: any = [];
    const query: any = queryString.parse(window.location.search);

    Object.keys(query).forEach((key: string) => {
      if (key !== 'search' && key !== 'page' && key !== 'mode') {
        const values = query[key].split(',');
        values.forEach((value: any) => {
          result.push({
            id: key,
            value,
            label: key,
          });
        });
      }
    });

    if (query['search'] !== undefined) {
      setSearch(query['search']);
    }

    if (query['page'] !== undefined) {
      setPage(parseInt(query['page']) - 1);
    }

    if (query['mode'] !== undefined) {
      setType(query['mode']);
    }

    if (initialFilters === null) {
      setFilters(result);
    }
  }, []);

  useEffect(() => {
    setSearchParams(convertFilters(filters, search, page, type));
    setSelectedElements([]);

    loadData();
  }, [filters, page, search, type, sortDir, sortColumn]);

  const loadData = async () => {
    if (source) {
      source.cancel();
    }

    source = CancelToken.source();

    setLoading(true);
    const response: any = await config.functions.loadElementsMethod({
      cancelToken: source.token,
      token,
      sortDir,
      sortColumn,
      search,
      filters: getPreparedFilters(),
      offset: page * 100,
      limit: 100,
    });
    setElements(
      config.functions.convertElementsMethod
        ? config.functions.convertElementsMethod(response[config.key])
        : response[config.key]
    );
    setAggregators(response.aggregators ? response.aggregators : {});
    setCount(response.count);
    setLoading(false);
  };

  const getPreparedFilters = () => {
    return JSON.stringify(
      filters.map((filter: any) =>
        JSON.stringify({
          id: filter.id,
          value:
            filter.type !== 'date'
              ? filter.value
              : JSON.stringify({
                  start:
                    filter.value.start !== null
                      ? moment(filter.value.start).format('YYYY-MM-DD')
                      : null,
                  end:
                    filter.value.end !== null
                      ? moment(filter.value.end).format('YYYY-MM-DD')
                      : null,
                }),
        })
      )
    );
  };

  const generateActionsLoadings = () => {
    const obj: TableActionLoadings = {};

    config.actions &&
      config.actions.forEach((action: TableAction) => {
        obj[action.name] = [];
      });

    return obj;
  };

  const pagination = (
    <div className="table-page-paginations">
      <ReactPaginate
        forcePage={page}
        breakLabel="..."
        nextLabel=">"
        onPageChange={event => {
          setPage(event.selected);
        }}
        pageRangeDisplayed={3}
        pageCount={Math.ceil(count / 100)}
        previousLabel="<"
      />
    </div>
  );

  const getExportLink = () => {
    return `${API_URL}/${config.options?.exportButton?.link}?search=${search}&filters=${getPreparedFilters()}`;
  };

  const updateActionLoading = ({
    action,
    loading,
    ids,
  }: {
    action: string;
    loading: boolean;
    ids: number[];
  }) => {
    for (let i = 0; i < ids.length; ++i) {
      if (loading) {
        if (actionLoadings[action].indexOf(ids[i]) === -1) {
          setActionLoadings(actionLoadings => {
            return {
              ...actionLoadings,
              [action]: [...actionLoadings[action], ids[i]],
            };
          });
        }
      } else {
        setActionLoadings(actionLoadings => {
          return {
            ...actionLoadings,
            [action]: actionLoadings[action].filter(_id => ids[i] !== _id),
          };
        });
      }
    }
  };

  const onAction = async (action: TableAction, ids: number[]) => {
    if (action.function) {
      updateActionLoading({
        action: action.name,
        loading: true,
        ids,
      });

      await action.function(ids);

      if (action.successCallback) {
        setElements((elements: any) => {
          if (action.successCallback) {
            return [...action.successCallback(ids, elements)];
          }
          return [...elements];
        });
      }

      updateActionLoading({
        action: action.name,
        loading: false,
        ids,
      });
    }
  };

  return (
    <div className="table-page">
      <div className="table-page-top row">
        <span className="table-page-top-title">{config.title}</span>
        {filters.length > 0 && config.options?.exportButton && (
          <a download href={getExportLink()} className="table-page-button table-page-export-button">
            <span className="table-page-button-label">Export</span>
            <img className="table-page-button-icon" src="/images/export-icon.svg" alt="" />
          </a>
        )}
        {config.options.createButton && (
          <>
            {config.options && config?.options?.createButton?.action ? (
              <button
                onClick={() => {
                  config?.options?.createButton?.action && config.options.createButton.action();
                }}
                className="table-page-button table-page-create-button"
              >
                <span className="table-page-button-label">{config.options.createButton.label}</span>
                <img
                  className="table-page-button-icon"
                  src="/images/create-button-icon.svg"
                  alt=""
                />
              </button>
            ) : (
              <Link
                to={config?.options?.createButton?.link ? config.options.createButton.link : '/'}
                className="table-page-button table-page-create-button"
              >
                <span className="table-page-button-label">
                  {config?.options?.createButton?.label}
                </span>
                <img
                  className="table-page-button-icon"
                  src="/images/create-button-icon.svg"
                  alt=""
                />
              </Link>
            )}
          </>
        )}
        {config.options.hasSearch && (
          <TableSearchField
            search={search}
            onChange={(value: string) => {
              setPage(0);
              setSearch(value);
            }}
          />
        )}
        {config.options.hasSwitcher && (
          <TableSwitcher
            value={type}
            onChange={(type: string) => {
              setType(type);
            }}
          />
        )}
        <div className="table-page-top-right">
          {count > 100 && pagination}
          <span className="table-page-num-value">
            Total num: <b>{count}</b>
          </span>
        </div>
      </div>
      {config.presets && (
        <div className="table-page-presets">
          {config.presets.map((preset: any) => {
            return (
              <span
                className="table-page-presets-element"
                onClick={() => {
                  setFilters([...preset.filters]);
                }}
              >
                #{preset.label}
              </span>
            );
          })}
        </div>
      )}
      <TableActions
        selectedElements={selectedElements}
        actions={config.actions ? config.actions : []}
        onClear={() => {
          setSelectedElements([]);
        }}
        onSelect={action => {
          onAction && onAction(action, selectedElements);
        }}
      />
      <div className="table-page-dop-row row">
        <TableFilters
          configs={config.filters}
          filters={filters}
          onChange={(filters: any) => {
            setPage(0);
            setFilters(filters);
          }}
        />
        <div className="table-page-dop-row-right">
          {!loading && config.aggregators && (
            <div className="table-page-aggregators">
              {config.aggregators.map(aggregator => {
                return (
                  <span className="table-page-aggregators-element">
                    {aggregator.label}:{' '}
                    <b>
                      {aggregators.hasOwnProperty(aggregator.column)
                        ? aggregators[aggregator.column]
                        : ''}
                    </b>
                  </span>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {type === 'list' && (
        <Table
          sortColumn={sortColumn}
          sortDir={sortDir}
          config={config}
          values={elements}
          loading={loading}
          onChangeSelectedElements={elements => {
            setSelectedElements(elements);
          }}
          onChangeSort={(dir, column) => {
            setSortDir(dir);
            setSortColumn(column);
          }}
          selectedElements={selectedElements}
          onAction={(action, ids) => {
            onAction(action, ids);
          }}
          actionLoadings={actionLoadings}
        />
      )}
      {type === 'block' && config.options.blockView && (
        <div className="table-page-block-view">
          {loading ? (
            <div className="table-page-loader">
              <div className="lds-ripple">
                <div />
                <div />
              </div>
            </div>
          ) : (
            <div className={config.options.blockView.className}>
              {elements.map((element: any) => {
                return config.options.blockView?.obj(element);
              })}
            </div>
          )}
        </div>
      )}
      <div className="table-page-bottom-pagination">{count > 100 && pagination}</div>
    </div>
  );
}

export default TablePage;
