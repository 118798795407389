import React, { useState } from 'react';
import Field from '../../components/ui/Field';
import { formCheckErrors, formGenerateErrorsObject } from '../../core/form';
import { apiUserForgotPassword } from '../../actions/userActions';
import Modal from '../../components/ui/Modal';
import SuccessRestore from '../components/SuccessRestore';

const errorsList = {
  email: 'email',
};

const initialValues = {
  email: '',
};

function ModalForgotPassword({ onClose, open }: any) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList));
  const [loading, setLoading] = useState(false);
  const [activationEmail, setActivationEmail] = useState<null | string>(null);

  const changeField = (field: string, value: any) => {
    setValues({ ...values, [field]: value });
    setErrors({ ...errors, [field]: '' });
  };

  const Submit = async () => {
    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({ ...errorResponse.errors });
      return;
    }

    setLoading(true);

    try {
      const apiResponse = await apiUserForgotPassword(values.email);
      if (apiResponse.status) {
        setActivationEmail(values.email);
      } else {
        setErrors({ ...errors, email: apiResponse.error });
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (activationEmail !== null) {
    return (
      <Modal
        open={open}
        onClose={onClose}
        className="auth-window"
        content={
          <SuccessRestore
            email={activationEmail}
            onSignOut={() => {
              setActivationEmail(null);
              onClose();
            }}
          />
        }
      />
    );
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={
        <div className="auth-window">
          <span className="auth-window-close" onClick={onClose} />
          <span className="auth-window-title">Restore password</span>
          <span className="auth-window-sub-title">
            Enter the email address you used when you created account. We will send you instructions
            on how to recover your password
          </span>
          <div className="auth-window-elements">
            <Field
              className="auth-window-element"
              id="forgotEmail"
              label="Email *"
              type="input"
              options={{
                type: 'email',
              }}
              error={errors.email}
              value={values.email}
              onChange={value => changeField('email', value)}
            />
            <button
              className={`custom-button auth-window-button ${loading ? 'loading' : ''}`}
              onClick={Submit}
            >
              <span className="custom-button-label">Continue</span>
              {loading && (
                <span className="custom-button-spinner">
                  <span className="spinner" />
                </span>
              )}
            </button>
          </div>
        </div>
      }
    />
  );
}

export default ModalForgotPassword;
