import { CustomField } from './index';
import moment from 'moment';

export const portfolioFields: {
  [name: string]: CustomField;
} = {
  title: {
    id: 'portfolioTitle',
    label: 'Title',
    type: 'input',
  },
  filmingDate: {
    id: 'portfolioFilmingDate',
    label: 'Filming date',
    type: 'datetime',
    options: {
      maxDate: moment().toDate(),
    },
  },
  client: {
    id: 'portfolioClient',
    label: 'Client',
    type: 'input',
  },
  description: {
    id: 'portfolioDescription',
    label: 'Description',
    type: 'textarea',
    options: {
      minRows: 4,
    },
  },
  links: {
    id: 'portfolioLinks',
    label: 'Links',
    type: 'links',
  },
};
