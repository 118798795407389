import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useLocation } from 'react-router-dom';
import LoginModal from '../modals/LoginModal';
import RegisterModal from '../modals/RegisterModal';
import UserInfo from '../../components/ui/UserInfo';
import MobileMenu from './MobileMenu';

const no_auth_links = [
  {
    label: 'Contact Us',
    link: '/contact-us',
  },
];

const user_links = [
  {
    label: 'Profile',
    link: '/profile',
  },
  {
    label: 'Contact Us',
    link: '/contact-us',
  },
];

const stringer_links = [
  {
    label: 'My Profile',
    link: '/profile',
  },
  {
    label: 'Tasks',
    link: '/tasks',
  },
  {
    label: 'Briefs',
    link: '/briefs',
  },
  {
    label: 'Contact Us',
    link: '/contact-us',
  },
];

const client_links = [
  {
    label: 'My Profile',
    link: '/profile',
  },
  {
    label: 'Gallery',
    link: '/gallery',
  },
  {
    label: 'Tasks',
    link: '/tasks',
  },
  {
    label: 'Contact Us',
    link: '/contact-us',
  },
];

const admin_links = [
  {
    label: 'Gallery',
    link: '/gallery',
  },
  {
    label: 'Tasks',
    link: '/tasks',
  },
  {
    label: 'Briefs',
    link: '/briefs',
  },
  {
    label: 'Contact Us',
    link: '/contact-us',
  },
];

const sass_links = [
  {
    label: 'Gallery',
    link: '/gallery',
  },
  {
    label: 'Tasks',
    link: '/panel/tasks',
  },
  {
    label: 'Stringers',
    link: '/panel/stringers',
  },
  {
    label: 'Employees',
    link: '/panel/employees',
  },
  {
    label: 'Contact Us',
    link: '/contact-us',
  },
];

let panelLinks = [
  {
    label: 'Videos',
    to: '/panel/videos',
  },
  {
    label: 'Tasks',
    to: '/panel/tasks',
  },
  {
    label: 'Briefs',
    to: '/panel/briefs',
  },
  {
    label: 'Clients',
    to: '/panel/clients',
  },
  {
    label: 'Stringers',
    to: '/panel/stringers',
  },
  {
    label: 'Partners',
    to: '/panel/partners',
  },
  {
    label: 'Employees',
    to: '/panel/employees',
  },
  {
    label: 'Downloads',
    to: '/panel/downloads',
  },
  {
    label: 'Logs',
    to: '/panel/logs',
  },
];

function Header({
  onOpenSignInModal,
  onOpenSignOutModal,
}: {
  onOpenSignInModal?: () => void;
  onOpenSignOutModal?: () => void;
}) {
  const user = useSelector(state => state.user);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (user.user && user.user.role === 'admin') {
      document.body.style.paddingTop = '90px';
    } else {
      document.body.style.paddingTop = '70px';
    }
  }, [user]);

  let links: any = [];
  if (!user.isLoggedIn || user?.user?.role === 'partner') {
    links = no_auth_links;
  }

  if (user.user && user.user.role === 'user') {
    links = user_links;
  }

  if (user.user && user.user.role === 'stringer') {
    links = stringer_links;
  }

  if (user.user && user.user.role === 'client') {
    links = client_links;
  }
  if (user.user && user.user.role === 'admin') {
    if (user.user.company_id === 1) {
      links = admin_links;
    } else {
      links = sass_links;
    }
  }

  /*
  if (user.user && user.user.company_id === 2) {
    panelLinks = panelLinks.filter(panelLink => ['Stringers', 'Tasks', 'Employees'].indexOf(panelLink.label) !== -1)
  }*/

  if (user.user && parseInt(user.user.admin) === 0) {
    panelLinks = panelLinks.filter(
      panelLink => ['Downloads', 'Logs', 'Employees'].indexOf(panelLink.label) === -1
    );

    if (parseInt(user.user.ugc) === 0) {
      panelLinks = panelLinks.filter(
        panelLink => ['Videos', 'Briefs'].indexOf(panelLink.label) === -1
      );
    }
    if (parseInt(user.user.producer) === 0) {
      panelLinks = panelLinks.filter(
        panelLink => ['Tasks', 'Clients'].indexOf(panelLink.label) === -1
      );
    }
  }

  return (
    <>
      <div className="site-header-container">
        <NavLink to="/" className="site-header-logo">
          <img src="/images/logo.png" alt="" />
        </NavLink>
        <ul className="site-header-menu">
          {links.map((link: any) => {
            return (
              <li>
                <NavLink to={link.link}>{link.label}</NavLink>
              </li>
            );
          })}
        </ul>
        {user.isLoggedIn ? (
          <>
            {['client'].indexOf(user.user.role) !== -1 && (
              <div className="site-header-right">
                <Link to={'/tasks/create'} className="site-header-upload-button">
                  <i className="fa fa-plus-circle site-header-upload-button-icon" />
                  Create task
                </Link>
              </div>
            )}
            {['admin', 'stringer'].indexOf(user.user.role) !== -1 && user.user.company_id === 1 && (
              <div className="site-header-right">
                <Link to={'/video/upload'} className="site-header-upload-button">
                  <i className="fa fa-upload site-header-upload-button-icon" />
                  Upload video
                </Link>
              </div>
            )}
            <UserInfo />
          </>
        ) : (
          <div className="site-header-right">
            <span className="site-header-login-link" onClick={onOpenSignInModal}>
              Login
            </span>
            <button className="site-header-register-button" onClick={onOpenSignOutModal}>
              Register
            </button>
          </div>
        )}
        <MobileMenu
          links={links}
          onOpenSignOutModal={onOpenSignOutModal}
          onOpenSignInModal={onOpenSignInModal}
        />
      </div>
      {user.user && user.user.role === 'admin' && user.user.company_id === 1 && (
        <div className="site-panel-header">
          <div className="site-panel-header-container">
            {panelLinks.map(({ to, label }) => {
              return (
                <NavLink to={to} className="site-panel-header-element">
                  {label}
                </NavLink>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
