import React, { useEffect } from 'react';
import Modal from 'react-modal';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  content?: any;
  title?: string;
  className?: string;
};

const customStyles = {
  content: {
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function CustomModal({ open, onClose, content, title, className = 'modal-window' }: ModalProps) {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  return (
    <Modal
      isOpen={open}
      style={customStyles}
      closeTimeoutMS={200}
      shouldCloseOnOverlayClick={true}
      onRequestClose={onClose}
    >
      {content}
    </Modal>
  );
}

export default CustomModal;
