import React from 'react';

function Agreement() {
  return (
    <div className="custom-page">
      <div className="custom-page-container">
        <h1 className="custom-page-title">VideoContent Agreement.</h1>

        <div className="custom-page-content">
          <div className="custom-page-content-text">
            <p>
              STRINGERSHUB searches newsworthy and viral video content and gets permission from
              owners to use it in various media, including broadcast media and online.
            </p>
            <p>
              We would like to promote and distribute your content on your behalf. This agreement
              permits STRINGERSHUB and its partners to broadcast or republish your video content,
              meaning they can use it in whole or in part on TV, online websites and other media
              channels, including social media.
            </p>
            <p>
              STRINGERSHUB partners with a number of well-known publishers such as ITV, AP, Getty,
              Vice, NBC, CNN, RTVi and others.
            </p>
            <p>
              By accepting the STRINGERSHUB VideoContent Agreement and taking into account our
              distributing your content, you agree to the following:
            </p>

            <ol>
              <li>
                You affirm, represent, and warrant that you are the owner of the VideoContent, or
                that you have the necessary licenses, rights, consents, and permissions to grant
                STRINGERSHUB a license to the VideoContent. You also affirm, represent, and warrant
                that the VideoContent does not vest in some third party (e.g. your employer, if the
                Content was created by you in the course of your employment).
              </li>
              <li>
                Aside from the licenses granted herein, you retain all ownership rights to your
                VideoContent.
              </li>
              <li>
                You hereby grant STRINGERSHUB and its partners a worldwide, non-exclusive,
                royalty-free, perpetual, sub-licensable and transferable license to use, reproduce,
                distribute, edit, prepare derivative works of, display, and perform the VideoContent
                in any media format and through any media channels.
              </li>
              <li>
                You agree to the terms of our Privacy &amp; Cookies Policy. In particular, you agree
                that when we distribute your video to our partners, we may disclose your personal
                information to such partners in order for them to independently verify your video.
              </li>
            </ol>
          </div>
          <div className="custom-page-image">
            <img src="/images/operator.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agreement;
