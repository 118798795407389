import React, { useState } from 'react';
import Modal from '../ui/Modal';
import Button from '../ui/Button';
import { BaseModalProps } from './ConfirmModal';
import Field from '../ui/Field';
import { apiStringersSearch } from '../../actions/stringerActions';
import { apiVideoChangeAuthor } from '../../actions/videoActions';
import { useSelector } from 'react-redux';
import { StringerType } from '../../types/models';

function ChangeAuthorModal({
  onClose,
  onUpdate,
  open,
  videoId,
}: BaseModalProps & { videoId: any }) {
  const token = useSelector(state => state.user.token);

  const [loading, setLoading] = useState(false);
  const [stringer, setStringer] = useState<StringerType | null>(null);
  const [error, setError] = useState<undefined | string>(undefined);

  const handleConfirm = async () => {
    if (stringer === null) {
      setError('Please select new author');
      return;
    }

    setLoading(true);
    await apiVideoChangeAuthor(token, videoId, stringer?.user?.id);
    setLoading(false);
    setStringer(null);
    setError(undefined);

    onUpdate && onUpdate();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={
        <div className="modal-window confirm-window">
          <div className="modal-window-top">
            <span className="modal-window-top-title">{'Change author'}</span>
            <div className="modal-window-top-close" onClick={onClose} />
          </div>
          <div className="modal-window-content confirm-window-content">
            <Field
              id="changeAuthorStringer"
              label="Stringer"
              type="singleSearch"
              value={stringer}
              onChange={value => {
                setStringer(value);
                setError(undefined);
              }}
              error={error}
              options={{
                placeholder: 'Search stringer',
                onSearch: apiStringersSearch,
                selectCallback: (element: any) => {
                  return `${element.user.email} (${element.user && `${element.user.first_name} ${element.user.last_name}`})`;
                },
                secured: true,
                tips: [
                  {
                    label: 'andrew.steele@scopal.co.uk',
                    value: {
                      user: {
                        first_name: 'Andrew',
                        last_name: 'Steele',
                        id: 30430,
                        email: 'andrew.steele@scopal.co.uk',
                      },
                    },
                  },
                  {
                    label: 'cctv@stringershub.com',
                    value: {
                      user: {
                        first_name: 'CCTV',
                        last_name: '',
                        id: 31009,
                        email: 'cctv@stringershub.com',
                      },
                    },
                  },
                  {
                    label: 'idf@idf.il',
                    value: {
                      user: {
                        first_name: 'IDF',
                        last_name: '',
                        id: 31285,
                        email: 'idf@idf.il',
                      },
                    },
                  },
                ],
              }}
            />
          </div>
          <div className="modal-window-bottom">
            <div className="modal-window-bottom-buttons">
              <button className="modal-window-bottom-cancel-button" onClick={onClose}>
                Cancel
              </button>
              <Button
                className={'modal-window-bottom-submit-button'}
                label={'Submit'}
                loading={loading}
                onClick={handleConfirm}
                type={'button'}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default ChangeAuthorModal;
