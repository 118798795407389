import React, { useEffect, useState } from 'react';
import Loading from '../../components/ui/Loading';
import Button from '../../components/ui/Button';
import queryString from 'query-string';
import { apiUserActivate, apiUserChangePassword, apiUserRegister } from '../../actions/userActions';
import { userSignInAction } from '../../reducers/user';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formCheckErrors, formGenerateErrorsObject } from '../../core/form';
import Field from '../../components/ui/Field';

const errorsList = {
  password: 'password',
  repeatPassword: 'repeat',
};

const initialValues = {
  password: '',
  repeatPassword: '',
};

function Activate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList));

  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const query: any = queryString.parse(window.location.search);
    if (query['token'] !== undefined && query['id'] !== undefined) {
      activate(query['token'], query['id']);
    } else {
      setError(true);
      setLoading(false);
    }
  }, []);

  const changeField = (field: string, value: any) => {
    setValues({ ...values, [field]: value });
    setErrors({ ...errors, [field]: '' });
  };

  const activate = async (token: string, id: string) => {
    const response = await apiUserActivate(token, id);
    if (response.status) {
      if (response.user.need_restore === 1) {
        setChangePassword(true);
        setLoading(false);
        setToken(response.token);
      } else {
        dispatch(
          userSignInAction({
            token: response.token,
            user: response.user,
          })
        );
        navigate('/profile');
      }
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const submit = async () => {
    const errorResponse = formCheckErrors(errorsList, errors, values);
    console.log(errorResponse);

    if (errorResponse.hasError) {
      setErrors({ ...errorResponse.errors });
      return;
    }

    setSubmitLoading(true);

    try {
      if (token) {
        const apiResponse = await apiUserChangePassword(token, values.password);
        if (apiResponse.status) {
          dispatch(
            userSignInAction({
              token: apiResponse.token,
              user: apiResponse.user,
            })
          );
          navigate('/profile');
        } else {
          setErrors({ ...errors, email: apiResponse.error });
          setSubmitLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (changePassword) {
    return (
      <div className="error-page">
        <div className="error-page-container">
          <h1 className="error-page-title">Oh, shoot</h1>
          <span className="error-page-sub-title">Your password has expired.</span>
          <div className="error-page-text">Please enter a new password</div>
          <div className="error-page-fields">
            <Field
              className="auth-window-element"
              id="loginPassword"
              error={errors.password}
              label="Password *"
              type="input"
              options={{
                type: 'password',
              }}
              value={values.password}
              onChange={value => changeField('password', value)}
            />
            <Field
              className="auth-window-element"
              id="loginRepeatPassword"
              error={errors.repeatPassword}
              label="Repeat Password *"
              type="input"
              options={{
                type: 'password',
              }}
              value={values.repeatPassword}
              onChange={value => changeField('repeatPassword', value)}
            />
          </div>
          <Button
            className={'blue error-page-resend-button'}
            label={'Change password'}
            loading={submitLoading}
            onClick={() => {
              submit();
            }}
            type={'button'}
          />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="error-page">
        <div className="error-page-container">
          <h1 className="error-page-title">Oh, shoot</h1>
          <span className="error-page-sub-title">We couldn't activate your account</span>
          <div className="error-page-text">
            You can try again <br />
            We can resend the activation email
          </div>
          <Button
            className={'blue error-page-resend-button'}
            label={'Resend'}
            loading={false}
            onClick={() => {}}
            type={'button'}
          />
        </div>
      </div>
    );
  }

  return <div />;
}

export default Activate;
