import React from 'react';
import { useSelector } from 'react-redux';

function BottomArea({ onOpenSignOutModal }: { onOpenSignOutModal?: () => void }) {
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);

  if (!isLoggedIn) {
    return (
      <div className="landing-bottom-area">
        <span className="landing-bottom-area-title">Register now and connect</span>
        <span className="landing-bottom-area-sub-title">
          To the growing community of stringers and media buyers
        </span>
        <button className="landing-bottom-area-button" onClick={onOpenSignOutModal}>
          Register now
        </button>
      </div>
    );
  }

  return <div />;
}

export default BottomArea;
