import React from 'react';
import LocationSelect, { Location } from './LocationSelect';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MultiSelect from './MultiSelect';
import MultiSearch from './MultiSearch';
import SingleSelect from './SingleSelect';
import SingleSearch from './SingleSearch';
import TextareaAutosize from 'react-textarea-autosize';
import { TaskLinks } from '../../pages/panel/tasks/components/TaskLinks';

type LabelProps = {
  type: string;
  className?: string;
  id: string;
  value: any;
  error?: string;
  label: string;
  required?: boolean;
  onChange: (value: any) => void;
  options?: any;
  placeholder?: string;
};

function Field({
  type,
  className,
  id,
  value,
  error,
  label,
  onChange,
  options,
  placeholder,
  required,
}: LabelProps) {
  const hasError = error !== undefined && error !== '';

  return (
    <div className={`custom-text-field ${className && className} ${hasError && 'error'}`}>
      <label htmlFor={id} className="custom-text-field-label">
        {label} {required && '*'}
      </label>
      <div className="custom-text-field-question" />
      <div className="custom-text-field-input-inner">
        {type === 'input' && (
          <>
            <input
              className="custom-text-field-input"
              id={id}
              disabled={options?.disabled}
              type={options && options.type !== undefined ? options.type : 'text'}
              value={value}
              onChange={event => onChange(event.target.value)}
            />
            {options?.tips && options?.tips.length > 0 && (
              <div className="custom-text-field-input-tips">
                {options.tips.map((tip: { value: any; label: string }) => {
                  return (
                    <span
                      className="custom-text-field-input-tip"
                      onClick={() => {
                        onChange(tip.value);
                      }}
                    >
                      {tip.label}
                    </span>
                  );
                })}
              </div>
            )}
          </>
        )}
        {type === 'links' && (
          <TaskLinks
            links={value}
            onChange={links => {
              onChange(links);
            }}
          />
        )}
        {type === 'multiSelect' && (
          <MultiSelect
            selectedElements={value}
            onChangeSelectedElements={(elements: any) => {
              onChange(elements);
            }}
            onLoadElements={options.onLoadElements}
            placeholder={options.placeholder}
          />
        )}
        {type === 'multiSearch' && (
          <MultiSearch
            selectedElements={value}
            onChangeSelectedElements={(elements: any) => {
              onChange(elements);
            }}
            onSearch={options.onSearch}
            placeholder={options.placeholder}
          />
        )}
        {type === 'singleSearch' && (
          <SingleSearch
            value={value}
            onChange={onChange}
            onSearch={options.onSearch}
            placeholder={placeholder}
            selectCallback={options.selectCallback}
            secured={options.secured}
            tips={options.tips}
          />
        )}
        {type === 'singleSelect' && (
          <SingleSelect
            value={value}
            onChange={onChange}
            elements={options.elements}
            onLoadElements={options.onLoadElements}
            placeholder={placeholder}
            secured={options.secured}
          />
        )}
        {type === 'location' && (
          <LocationSelect
            initialValue={value}
            onChange={(location: Location) => onChange(location)}
          />
        )}
        {type === 'textarea' && (
          <TextareaAutosize
            className="custom-text-field-textarea"
            id={id}
            value={value}
            minRows={options && options.minRows ? options.minRows : 1}
            onChange={event => onChange(event.target.value)}
          />
        )}
        {type === 'datetime' && (
          <div className="custom-text-field-date">
            <DatePicker
              selected={value}
              onChange={date => onChange(date)}
              minDate={options?.minDate}
              maxDate={options?.maxDate}
              peekNextMonth
              dropdownMode="select"
            />
            <img src="/images/calendar.svg" className="custom-text-field-date-icon" />
          </div>
        )}
        {type === 'dateRange' && (
          <div className="custom-text-field-date-range">
            <div className="custom-text-field-date">
              <DatePicker
                selected={value.start}
                onChange={date =>
                  onChange({
                    ...value,
                    start: date,
                  })
                }
                selectsStart
                peekNextMonth
                dropdownMode="select"
              />
              <img src="/images/calendar.svg" className="custom-text-field-date-icon" />
            </div>
            <div className="custom-text-field-date">
              <DatePicker
                selected={value.end}
                onChange={date =>
                  onChange({
                    ...value,
                    end: date,
                  })
                }
                selectsEnd
                peekNextMonth
                dropdownMode="select"
              />
              <img src="/images/calendar.svg" className="custom-text-field-date-icon" />
            </div>
          </div>
        )}
      </div>
      {hasError && <div className="custom-text-field-error-text">{error}</div>}
    </div>
  );
}

export default Field;
