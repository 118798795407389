import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import SingleSearch from '../../../components/ui/SingleSearch';
import {
  apiVideoCitiesSearch,
  apiVideoCountrySearch,
  apiVideoTagSearch,
} from '../../../actions/videoActions';
import { apiCategoriesGet } from '../../../actions/categoriesActions';
import SingleSelect from '../../../components/ui/SingleSelect';
import moment from 'moment';
import { useSelector } from 'react-redux';

function GalleryFilters({ filters, onChange, sort, onChangeSort, search, onChangeSearch }: any) {
  const [categories, setCategories] = useState<any>([]);
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    const categoriesResponse = await apiCategoriesGet();
    setCategories(categoriesResponse);
  };

  const checkFilter = (id: string, category: any) => {
    return (
      filters.find((filter: any) => filter.id === id && filter.value === category) !== undefined
    );
  };

  const selectCategory = (category: any) => {
    if (checkFilter('Category', category)) {
      deleteFilter('Category', category);
    } else {
      onChange([
        ...filters,
        {
          id: 'Category',
          label: 'Category',
          value: category,
        },
      ]);
    }
  };

  const selectSource = (partner: any) => {
    if (checkFilter('Partner', partner)) {
      deleteFilter('Partner', partner);
    } else {
      onChange([
        ...filters,
        {
          id: 'Partner',
          label: 'Partner',
          value: partner,
        },
      ]);
    }
  };

  const deleteFilter = (id: string, value: string) => {
    onChange(
      filters.filter(
        (filter: any) => (filter.value !== value && filter.id === id) || filter.id !== id
      )
    );
  };

  const getDateFrom = () => {
    const dateFrom = filters.find((filter: any) => filter.id === 'Filming Date From');
    if (dateFrom !== undefined) {
      return moment(dateFrom.value).toDate();
    }
    return null;
  };

  const getDateTo = () => {
    const dateTo = filters.find((filter: any) => filter.id === 'Filming Date To');
    if (dateTo !== undefined) {
      return moment(dateTo.value).toDate();
    }
    return null;
  };

  const changeDateFrom = (date: Date) => {
    onChange([
      ...filters.filter((filter: any) => filter.id !== 'Filming Date From'),
      {
        id: 'Filming Date From',
        label: 'Filming Date From',
        value: moment(date).format('YYYY-MM-DD'),
      },
    ]);
  };

  const changeDateTo = (date: Date) => {
    onChange([
      ...filters.filter((filter: any) => filter.id !== 'Filming Date To'),
      {
        id: 'Filming Date To',
        label: 'Filming Date To',
        value: moment(date).format('YYYY-MM-DD'),
      },
    ]);
  };

  return (
    <div className="gallery-videos-left-inner">
      <div className="gallery-filter-area">
        <span className="gallery-filter-area-title">Search</span>
        <div className="gallery-filter-area-input">
          <input
            type="text"
            value={search}
            onChange={event => {
              onChangeSearch(event.target.value);
            }}
            className="custom-text-field-input"
            placeholder={'Search video'}
          />
        </div>
      </div>
      <div className="gallery-filter-area">
        <span className="gallery-filter-area-title">Sort by</span>
        <div className="gallery-filter-area-input">
          <SingleSelect
            value={sort}
            onChange={(value: string) => {
              onChangeSort(value);
            }}
            elements={['Newest first', 'Oldest first']}
          />
        </div>
      </div>
      {user && (user.id === 31506 || user.id === 31246 || user.id === 2 || user.id === 30586) && (
        <div className="gallery-filter-area">
          <span className="gallery-filter-area-title">Source</span>
          <div className="gallery-filter-tips">
            {['CCTV', 'Kyodo', 'PA', 'EFE'].map((partner: string) => {
              return (
                <span
                  onClick={() => {
                    selectSource(partner);
                  }}
                  className={`gallery-filter-tip ${checkFilter('Partner', partner) && 'active'}`}
                >
                  {partner}
                </span>
              );
            })}
          </div>
        </div>
      )}
      {categories.length > 0 && (
        <div className="gallery-filter-area">
          <span className="gallery-filter-area-title">Category</span>
          <div className="gallery-filter-tips">
            {categories.map((category: any) => {
              return (
                <span
                  onClick={() => {
                    selectCategory(category);
                  }}
                  className={`gallery-filter-tip ${checkFilter('Category', category) && 'active'}`}
                >
                  {category}
                </span>
              );
            })}
          </div>
        </div>
      )}
      <div className="gallery-filter-area">
        <span className="gallery-filter-area-title">Filming date</span>
        <div className="gallery-filter-date-range">
          <div className="gallery-filter-date-range-input">
            <DatePicker selected={getDateFrom()} onChange={changeDateFrom} selectsStart />
          </div>
          <span className="gallery-filter-date-range-separator">-</span>
          <div className="gallery-filter-date-range-input">
            <DatePicker selected={getDateTo()} onChange={changeDateTo} selectsEnd />
          </div>
        </div>
      </div>
      <div className="gallery-filter-area">
        <span className="gallery-filter-area-title">Tags</span>
        <div className="gallery-filter-area-input">
          <SingleSearch
            value={null}
            onChange={(value: any) => {
              if (!checkFilter(value, 'Tag')) {
                onChange([
                  ...filters,
                  {
                    id: 'Tag',
                    label: 'Tag',
                    value: value,
                  },
                ]);
              }
            }}
            onSearch={apiVideoTagSearch}
            placeholder={'Please search tag'}
          />
        </div>
        <div className="gallery-filter-tips">
          {filters
            .filter((filter: any) => filter.id === 'Tag')
            .map((filter: any) => {
              return (
                <div className="gallery-filter-tip-with-close">
                  <span className="gallery-filter-tip-with-close-label">{filter.value}</span>
                  <span
                    className="span gallery-filter-tip-with-close-icon"
                    onClick={() => {
                      deleteFilter('Tag', filter.value);
                    }}
                  >
                    x
                  </span>
                </div>
              );
            })}
        </div>
      </div>
      <div className="gallery-filter-area">
        <span className="gallery-filter-area-title">Country</span>
        <div className="gallery-filter-area-input">
          <SingleSearch
            value={null}
            onChange={(value: any) => {
              if (!checkFilter(value, 'Country')) {
                onChange([
                  ...filters,
                  {
                    id: 'Country',
                    label: 'Country',
                    value: value,
                  },
                ]);
              }
            }}
            onSearch={apiVideoCountrySearch}
            placeholder={'Please search country'}
          />
        </div>
        <div className="gallery-filter-tips">
          {filters
            .filter((filter: any) => filter.id === 'Country')
            .map((filter: any) => {
              return (
                <div className="gallery-filter-tip-with-close">
                  <span className="gallery-filter-tip-with-close-label">{filter.value}</span>
                  <span
                    className="span gallery-filter-tip-with-close-icon"
                    onClick={() => {
                      deleteFilter('Country', filter.value);
                    }}
                  >
                    x
                  </span>
                </div>
              );
            })}
        </div>
      </div>
      <div className="gallery-filter-area">
        <span className="gallery-filter-area-title">City</span>
        <div className="gallery-filter-area-input">
          <SingleSearch
            value={null}
            onChange={(value: any) => {
              if (!checkFilter(value, 'City')) {
                onChange([
                  ...filters,
                  {
                    id: 'City',
                    label: 'City',
                    value: value,
                  },
                ]);
              }
            }}
            onSearch={apiVideoCitiesSearch}
            placeholder={'Please search city'}
          />
        </div>
        <div className="gallery-filter-tips">
          {filters
            .filter((filter: any) => filter.id === 'City')
            .map((filter: any) => {
              return (
                <div className="gallery-filter-tip-with-close">
                  <span className="gallery-filter-tip-with-close-label">{filter.value}</span>
                  <span
                    className="span gallery-filter-tip-with-close-icon"
                    onClick={() => {
                      deleteFilter('City', filter.value);
                    }}
                  >
                    x
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default GalleryFilters;
