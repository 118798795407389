import { apiStringersGet } from '../../../actions/stringerActions';

import {
  apiStringerCitiesSearch,
  apiStringerCountrySearch,
  apiStringerStateSearch,
} from '../../../actions/stringerActions';
import TablePage, {
  TableConfig,
  TableFilter,
  TableHead,
  TablePreset,
} from '../../../components/table/TablePage';
import { StringerResponse } from '../../../types/responses';
import useModal from '../../../hooks/useModal';
import InviteStringerModal from '../../../components/modals/InviteStringerModal';

const configHeads: TableHead[] = [
  {
    name: '',
    width: '20px',
    key: 'empty',
  },
  {
    name: 'Name',
    width: null,
    key: 'name',
    type: 'link',
    options: {
      href: '/panel/stringers/',
    },
  },
  {
    name: 'Email',
    width: '170px',
    key: 'email',
    type: 'string',
  },
  {
    name: 'Phone',
    width: '175px',
    key: 'phone',
    type: 'string',
  },
  {
    name: 'Location',
    width: '230px',
    key: 'location_text',
    type: 'string',
  },
  {
    name: 'Status',
    width: '145px',
    key: 'status',
    type: 'status',
    options: {
      circle: {
        0: 'red',
        1: 'green',
      },
      label: {
        0: 'Not active',
        1: 'Activated',
      },
    },
  },
  {
    name: 'Register date',
    width: '90px',
    key: 'created_at',
    type: 'date',
    options: {
      format: 'DD/MM/YYYY HH:mm:ss',
    },
  },
];

const configFilters: TableFilter[] = [
  {
    id: 'Status',
    type: 'select',
    label: 'Status',
    options: {
      label: 'Please select status',
      elements: ['Active', 'Not activated'],
    },
  },
  {
    id: 'Country',
    type: 'search',
    label: 'Country',
    options: {
      label: 'Please search country',
      onSearch: apiStringerCountrySearch,
    },
  },
  {
    id: 'State',
    type: 'search',
    label: 'State/Region',
    options: {
      label: 'Please search state or region',
      onSearch: apiStringerStateSearch,
    },
  },
  {
    id: 'City',
    type: 'search',
    label: 'City',
    options: {
      label: 'Please search city',
      onSearch: apiStringerCitiesSearch,
    },
  },
  {
    id: 'Referrer',
    type: 'select',
    label: 'Referrer',
    options: {
      label: 'Please select referrer',
      elements: ['Da', 'P', 'L', 'K', 'M', 'D', 'Yu', 'Y', 'Sc'],
    },
  },
  {
    id: 'Other',
    type: 'select',
    label: 'Other',
    options: {
      label: 'Please select filter',
      elements: ['Uploaded videos', 'Completed tasks'],
    },
  },
];

const configPresets: TablePreset[] = [
  {
    label: 'Uploaded videos',
    filters: [
      {
        id: 'Other',
        label: 'Other',
        value: 'Uploaded videos',
      },
    ],
  },
  {
    label: 'Referrer P/L/K/M/D/Da',
    filters: ['P', 'L', 'K', 'M', 'D', 'Da'].map(el => {
      return {
        id: 'Referrer',
        label: 'Referrer',
        value: el,
      };
    }),
  },
];

const config: TableConfig = {
  title: 'Stringers',
  key: 'stringers',
  functions: {
    loadElementsMethod: apiStringersGet,
    convertElementsMethod: (elements: StringerResponse[]) => [
      ...elements.map(stringer => {
        return {
          ...stringer,
          name: [stringer.user.first_name, stringer.user.last_name].join(' '),
          email: stringer.user.email,
          status: stringer.user.status,
        };
      }),
    ],
  },
  options: {
    hasSearch: true,
    hasSwitcher: false,
  },
  heads: configHeads,
  filters: configFilters,
  presets: configPresets,
};

function PanelStringers() {
  const [inviteModalOpen, openInviteModal, closeInviteModal] = useModal();

  return (
    <>
      <InviteStringerModal open={inviteModalOpen} onClose={closeInviteModal} />
      <TablePage
        config={{
          ...config,
          options: {
            ...config.options,
            createButton: {
              label: 'Invite stringer',
              action: () => {
                openInviteModal();
              },
            },
          },
        }}
      />
    </>
  );
}

export default PanelStringers;
