import thunk from 'redux-thunk';
import { userReducer } from './user';
import { applyMiddleware, combineReducers, createStore } from 'redux';

type AppState = {
  user: any;
};

declare module 'react-redux' {
  interface DefaultRootState extends AppState {}
}

const rootReducer = combineReducers({
  user: userReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
