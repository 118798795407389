import React, { useState } from 'react';
import TableFilterModal from './TableFilterModal';
import moment from 'moment';

export const getFilterDateValue = (value: any) => {
  let result = '';

  if (value.start) {
    result += moment(value.start).format('DD/MM/YYYY');
  }

  if (value.end) {
    if (value.start) {
      result += ' - ';
    }
    result += moment(value.end).format('DD/MM/YYYY');
  }

  return result;
};

function TableFilters({ filters, onChange, configs }: any) {
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const deleteFilter = (index: number) => {
    filters.splice(index, 1);
    onChange([...filters]);
  };

  return (
    <div className="table-page-filters">
      <TableFilterModal
        configs={configs}
        filters={filters}
        onClose={() => {
          setFilterModalOpen(false);
        }}
        onSubmit={(_filters: any) => {
          onChange([...filters, ..._filters]);
          setFilterModalOpen(false);
        }}
        open={filterModalOpen}
      />
      {configs.length > 0 && (
        <button
          className="table-page-filters-create-button"
          onClick={() => {
            setFilterModalOpen(true);
          }}
        >
          <span className="table-page-filters-create-button-label">Add filter</span>
          <span className="table-page-filters-create-button-icon">+</span>
        </button>
      )}
      {filters.map((filter: any, index: number) => {
        return (
          <div className="table-page-filters-tip">
            <span className="table-page-filters-tip-label">
              {filter.label}:{' '}
              {filter.type !== 'date' ? filter.value : getFilterDateValue(filter.value)}
            </span>
            <span
              className="table-page-filters-tip-close"
              onClick={() => {
                deleteFilter(index);
              }}
            >
              x
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default TableFilters;
