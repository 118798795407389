import React from 'react';
import Faq from './blocks/Faq/Faq';
import Promo from './blocks/Promo/Promo';

function Landing() {
  return (
    <>
      <Promo />
      <Faq />
    </>
  );
}

export default Landing;
