import React from 'react';

function Tabs({
  tabs,
  currentTab,
  onChange,
  className,
  disabledTabs,
}: {
  tabs: string[];
  currentTab: string;
  onChange: (tab: string) => void;
  className?: string;
  disabledTabs?: string[];
}) {
  return (
    <div className={`task-page-tabs ${className && className}`}>
      {tabs.map(tab => {
        if (tab === currentTab) {
          return <span className="task-page-tabs-element active">{tab}</span>;
        }
        if (disabledTabs && disabledTabs.indexOf(tab) !== -1) {
          return <span className="task-page-tabs-element disabled">{tab}</span>;
        }
        return (
          <span
            className="task-page-tabs-element"
            onClick={() => {
              onChange(tab);
            }}
          >
            {tab}
          </span>
        );
      })}
    </div>
  );
}

export default Tabs;
