import React from 'react';

function TableSearchField({ search, onChange }: any) {
  return (
    <div className="table-page-search-area open">
      <i className="fa fa-search table-page-search-area-icon" />
      <input
        type="text"
        placeholder="Search"
        className="table-page-search-area-input"
        value={search}
        onChange={event => {
          onChange(event.target.value);
        }}
      />
    </div>
  );
}

export default TableSearchField;
