import React, { useState } from 'react';
import Field from '../ui/Field';
import Modal from '../ui/Modal';
import Button from '../ui/Button';

function TableFilterModal({ configs, onClose, open, onSubmit }: any) {
  const [selectedConfig, setSelectedConfig] = useState<any>(null);
  const [value, setValue] = useState(null);

  const submit = () => {
    if (selectedConfig.type === 'textarea') {
      // @ts-ignore
      const values = value.split('\n');
      onSubmit(
        values.map((value: any) => {
          return {
            id: selectedConfig.id,
            label: selectedConfig.label,
            type: selectedConfig.type,
            value,
          };
        })
      );
    } else {
      onSubmit([
        {
          id: selectedConfig.id,
          label: selectedConfig.label,
          type: selectedConfig.type,
          value,
        },
      ]);
    }

    setValue(null);
    setSelectedConfig(null);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={
        <div className="modal-window filter-modal-window">
          <div className="modal-window-top">
            <span className="modal-window-top-title">Add filter</span>
            <div className="modal-window-top-close" onClick={onClose} />
          </div>
          <div className="modal-window-content filter-modal-window-content">
            <Field
              className="filter-modal-select"
              id="filterId"
              label="Filter"
              type={'singleSelect'}
              value={selectedConfig !== null ? selectedConfig.id : null}
              placeholder={'Please select filter'}
              options={{
                elements: configs.map((config: any) => config.id),
              }}
              onChange={value => {
                const config = configs.find((_config: any) => _config.id === value);
                setSelectedConfig({
                  ...(config !== undefined ? config : null),
                });
                setValue(null);
              }}
            />
            {selectedConfig !== null && (
              <div className="filter-modal-area">
                {selectedConfig.type === 'select' && (
                  <Field
                    className="filter-modal-select"
                    key={`filterField${selectedConfig.id}`}
                    id={`filterField${selectedConfig.id}`}
                    label={selectedConfig.label}
                    type={'singleSelect'}
                    value={value}
                    placeholder={selectedConfig.options.label}
                    options={selectedConfig.options}
                    onChange={value => {
                      setValue(value);
                    }}
                  />
                )}
                {selectedConfig.type === 'search' && (
                  <Field
                    className="filter-modal-select"
                    key={`filterField${selectedConfig.id}`}
                    id={`filterField${selectedConfig.id}`}
                    label={selectedConfig.label}
                    type={'singleSearch'}
                    value={value}
                    options={selectedConfig.options}
                    placeholder={selectedConfig.options.label}
                    onChange={(value: any) => {
                      setValue(value);
                    }}
                  />
                )}
                {selectedConfig.type === 'date' && (
                  <Field
                    className="filter-modal-select"
                    key={`filterField${selectedConfig.id}`}
                    id={`filterField${selectedConfig.id}`}
                    label={selectedConfig.label}
                    type={'dateRange'}
                    value={
                      value !== null
                        ? value
                        : {
                            start: null,
                            end: null,
                          }
                    }
                    onChange={(value: any) => {
                      setValue(value);
                    }}
                  />
                )}
                {selectedConfig.type === 'input' && (
                  <Field
                    className="filter-modal-select"
                    key={`filterField${selectedConfig.id}`}
                    id={`filterField${selectedConfig.id}`}
                    label={selectedConfig.label}
                    type={'input'}
                    value={value}
                    onChange={(value: any) => {
                      setValue(value);
                    }}
                  />
                )}
                {selectedConfig.type === 'textarea' && (
                  <Field
                    className="filter-modal-select"
                    key={`filterField${selectedConfig.id}`}
                    id={`filterField${selectedConfig.id}`}
                    label={selectedConfig.label}
                    type={'textarea'}
                    value={value}
                    onChange={(value: any) => {
                      setValue(value);
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div className="modal-window-bottom">
            <div className="modal-window-bottom-buttons">
              <button className="modal-window-bottom-cancel-button" onClick={onClose}>
                Cancel
              </button>
              {value !== null && (
                <Button
                  className={'modal-window-bottom-submit-button'}
                  label={'Submit'}
                  loading={false}
                  onClick={submit}
                  type={'button'}
                />
              )}
            </div>
          </div>
        </div>
      }
    />
  );
}

export default TableFilterModal;
