import axios from 'axios';
import { API_URL } from '../core/common';
import { TableLoadElementsParams } from '../components/table/TablePage';

export function apiPartnersGet(token: string, videoId: string): Promise<any> {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .get(API_URL + `/partners.get?video_id=${videoId}`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function apiPartnerSendVideo(token: string, partnerId: string, videoId: string) {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .post(
        API_URL + '/partner.send_video',
        {
          partner_id: partnerId,
          video_id: videoId,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function apiPartnersSendVideo(token: string, partnerIds: number[], videoId: string) {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .post(
        API_URL + '/partners.send_video',
        {
          partner_ids: partnerIds.join(','),
          video_id: videoId,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function apiPartnerDeleteVideo(token: string, partnerId: string, videoId: string) {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .post(
        API_URL + '/partner.delete_video',
        {
          partner_id: partnerId,
          video_id: videoId,
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function apiPartnersGetReceive(token: string) {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .get(API_URL + `/partners.get_receive`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data.partners);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function apiPartnersGetSend(token: string) {
  return new Promise<any[]>((resolve, reject) => {
    axios
      .get(API_URL + `/partners.get_send`, {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      .then(function (response) {
        resolve(response.data.partners);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export const apiPartnersGetInfo = ({
  cancelToken,
  token,
  search,
  filters,
  offset,
}: TableLoadElementsParams) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        API_URL +
          `/partners.get_info?offset=${offset}&limit=${100}&search=${search}&filters=${filters}`,
        {
          cancelToken,
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        if (!axios.isCancel(error)) {
          reject(error);
        }
      });
  });
};
