import React from 'react';
import TablePage, {
  TableAggregator,
  TableConfig,
  TableFilter,
  TableHead,
} from '../../../components/table/TablePage';
import {
  apiTaskCitiesSearch,
  apiTaskCountrySearch,
  apiTasksGet,
  apiTaskStateSearch,
} from '../../../actions/taskActions';
import { TaskResponse } from '../../../types/responses';
import { getTaskStatus } from '../../../core/helpers';
import { apiUserSearch } from '../../../actions/userActions';
import { useSelector } from 'react-redux';
import PageNotFound from '../../pagenotfound/PageNotFound';

const getStatus = (task: any) => {
  if (task.status === 0) {
    return 0;
  }

  if (task.status === 1) {
    return 1;
  }

  if (task.status === 2 && task.stringer_user_id === null) {
    return 2;
  }

  if (task.status === 2 && task.stringer_user_id !== null) {
    return 3;
  }

  if (
    task.status === 2 &&
    task.material_accepted === 1 &&
    task.stringer_received_payment === 0 &&
    task.customer_sent_payment === 1
  ) {
    return 4;
  }

  if (
    task.status === 2 &&
    task.material_accepted === 1 &&
    task.stringer_received_payment === 1 &&
    task.customer_sent_payment === 0
  ) {
    return 5;
  }
};

const configHeads: TableHead[] = [
  {
    name: '',
    width: '20px',
    key: 'empty',
  },
  {
    name: 'Title',
    width: null,
    key: 'title',
    type: 'link',
    sortable: true,
    options: {
      href: '/panel/tasks/',
    },
  },
  {
    name: 'Client',
    width: '160px',
    key: 'clientOrganization',
    type: 'string',
  },
  {
    name: 'Location',
    width: '230px',
    key: 'location_text',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Deadline',
    width: '90px',
    key: 'deadline',
    type: 'date',
    options: {
      format: 'DD/MM/YYYY',
    },
    sortable: true,
  },
  {
    name: 'Budget',
    width: '90px',
    key: 'budget',
    type: 'string',
    sortable: true,
  },
  {
    name: 'Status',
    width: '220px',
    key: 'taskStatus',
    type: 'status',
    options: {
      circle: {
        Rejected: 'red',
        'On moderation': 'orange',
        'Searching for a stringer': 'orange',
        Production: 'orange',
        Completed: 'red',
      },
      label: {
        Rejected: 'Rejected',
        'On moderation': 'On moderation',
        'Searching for a stringer': 'Searching for a stringer',
        Production: 'Production',
        Completed: 'Completed',
      },
    },
  },
  {
    name: 'Created at',
    width: '90px',
    key: 'created_at',
    type: 'date',
    sortable: true,
    options: {
      format: 'DD/MM/YYYY HH:mm:ss',
    },
  },
];

const configFilters: TableFilter[] = [
  {
    id: 'Status',
    type: 'select',
    label: 'Status',
    options: {
      label: 'Please select status',
      elements: [
        'Rejected',
        'On moderation',
        'Searching for a stringer',
        'Production',
        'Completed',
      ],
    },
  },
  {
    id: 'Country',
    type: 'search',
    label: 'Country',
    options: {
      label: 'Please search country',
      onSearch: apiTaskCountrySearch,
    },
  },
  {
    id: 'State',
    type: 'search',
    label: 'State/Region',
    options: {
      label: 'Please search state or region',
      onSearch: apiTaskStateSearch,
    },
  },
  {
    id: 'City',
    type: 'search',
    label: 'City',
    options: {
      label: 'Please search city',
      onSearch: apiTaskCitiesSearch,
    },
  },
  {
    id: 'Created At',
    type: 'date',
    label: 'Created At',
  },
  {
    id: 'Executor',
    type: 'search',
    label: 'Executor',
    options: {
      label: 'Please search stringer',
      onSearch: apiUserSearch,
      secured: true,
    },
  },
  {
    id: 'Client',
    type: 'search',
    label: 'Client',
    options: {
      label: 'Please search client',
      onSearch: apiUserSearch,
      secured: true,
    },
  },
];

const configAggregators: TableAggregator[] = [
  {
    label: 'Total budget',
    column: 'budget',
  },
];

const config: TableConfig = {
  title: 'Tasks',
  key: 'tasks',
  sort: {
    dir: 'DESC',
    column: 'created_at',
  },
  functions: {
    loadElementsMethod: apiTasksGet,
    convertElementsMethod: (elements: TaskResponse[]) => {
      return elements.map(element => {
        return {
          ...element,
          taskStatus: getTaskStatus(element),
          clientOrganization: element.user ? element.user.client?.organization : null,
        };
      });
    },
  },
  options: {
    hasSearch: true,
    hasSwitcher: false,
    createButton: {
      label: 'Create task',
      link: '/panel/tasks/create',
    },
  },
  heads: configHeads,
  filters: configFilters,
  aggregators: configAggregators,
};

function PanelTasks() {
  const user = useSelector(state => state.user);

  if (user.user && parseInt(user.user.admin) === 0 && parseInt(user.user.producer) === 0) {
    return <PageNotFound />;
  }

  return <TablePage config={config} />;
}

export default PanelTasks;
