import React, { useEffect, useState } from 'react';

export function TaskLinks({
  links,
  onChange,
}: {
  links: string[];
  onChange: (links: string[]) => void;
}) {
  const [input, setInput] = useState('');

  return (
    <div className="task-page-input-links">
      {links.map((link, index) => {
        return (
          <div className="task-page-input-links-element">
            <input
              type="text"
              className="task-page-input-links-element-input"
              value={link}
              onChange={event => {
                links[index] = event.target.value;
                onChange([...links]);
              }}
            />
            <img
              src="/images/trash.svg"
              className="task-page-input-links-element-icon"
              onClick={() => {
                links.splice(index, 1);
                onChange([...links]);
              }}
            />
          </div>
        );
      })}
      <div className="task-page-input-links">
        <div className="task-page-input-links-element">
          <input
            type="text"
            className="task-page-input-links-element-input"
            value={input}
            onChange={event => {
              setInput(event.target.value);
            }}
          />
          <img
            src="/images/circle-plus.svg"
            className="task-page-input-links-element-icon"
            onClick={() => {
              if (input.trim().length > 0) {
                onChange([...links, input]);
                setInput('');
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
