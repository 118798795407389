import React, { useEffect, useState } from 'react';
import { apiTaskCreateNote, apiTaskGetNotes } from '../../../../actions/taskActions';
import { useSelector } from 'react-redux';
import moment from 'moment';

export function TaskComments({ taskId }: { taskId: number }) {
  const token = useSelector(state => state.user.token);
  const [notes, setNotes] = useState<Record<string, any>>([]);

  useEffect(() => {
    loadNotes();
  }, [taskId]);

  const loadNotes = async () => {
    const notes = await apiTaskGetNotes(token, taskId);
    setNotes(groupNotes(notes));
  };

  const groupNotes = (notes: any[]): Record<string, any> => {
    const result: Record<string, any> = [];

    notes.forEach((note: any) => {
      const date = moment(note.created_at).format('DD/MM/YYYY');

      if (result[date] === undefined) {
        result[date] = [];
      }
      result[date].push(note);
    });

    return result;
  };

  console.log('notes', notes);

  return (
    <div className="task-page-block">
      <div className="task-page-block-top">
        <span className="task-page-block-title">Notes</span>
      </div>
      <div className="task-page-notes">
        {Object.keys(notes).map(date => {
          return (
            <div className="task-page-notes-area">
              <span className="task-page-notes-area-date">{date}</span>
              <div className="task-page-notes-area-elements">
                {notes[date].map((note: any) => {
                  return (
                    <div className="task-page-notes-area-element row">
                      <span className="task-page-notes-area-element-time">
                        {moment(note.created_at).format('HH:mm')}
                      </span>
                      <span className="task-page-notes-area-element-user">
                        User:{' '}
                        <span>
                          {note.user.first_name} {note.user.last_name}:
                        </span>
                      </span>
                      <span className="task-page-notes-area-element-text">{note.value}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
        <TaskNotesCreateArea
          taskId={taskId}
          onUpdate={() => {
            loadNotes();
          }}
        />
      </div>
    </div>
  );
}

function TaskNotesCreateArea({ onUpdate, taskId }: { onUpdate: () => void; taskId: number }) {
  const token = useSelector(state => state.user.token);

  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState('');

  const onCreate = async () => {
    if (note.trim().length > 0) {
      setLoading(true);
      await apiTaskCreateNote(token, taskId, note);
      setNote('');
      setLoading(false);
      onUpdate();
    }
  };

  return (
    <div className="task-page-notes-create">
      <input
        type="text"
        placeholder="Enter your note..."
        className="task-page-notes-create-input"
        value={note}
        onChange={event => {
          setNote(event.target.value);
        }}
      />
      <button onClick={onCreate} disabled={loading} className="task-page-notes-create-button">
        {loading ? <div className="spinner" /> : 'Send'}
      </button>
    </div>
  );
}
