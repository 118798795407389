import React from 'react';

type ActivationProps = {
  email: string;
  onSignOut: Function;
};

function SuccessRestore({ email }: ActivationProps) {
  return (
    <div className="activation-window">
      <div className="activation-window-top">
        <div className="activation-window-top-icon" />
      </div>
      <div className="activation-window-content">
        <span className="activation-window-title">Almost ready</span>
        <span className="activation-window-text">
          Mail has been sent to your e-mail <b> {email} </b> with a link to restore your account.
        </span>
      </div>
    </div>
  );
}

export default SuccessRestore;
