import React from 'react';
import { NavLink } from 'react-router-dom';

function Footer() {
  return (
    <div className="site-copyright">
      <div className="site-copyright-container">
        <span className="site-copyright-text">© 2025 , Stringershub.com. All rights reserved</span>
        <ul className="site-copyright-links">
          <li>
            <NavLink to="/privacy_policy">Privacy Policy</NavLink>
          </li>
          <li>
            <NavLink to="/terms_of_services">Terms of Services</NavLink>
          </li>
          <li>
            <NavLink to="/agreement">Agreement</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
