import React, { useState } from 'react';
import Modal from '../ui/Modal';
import Button from '../ui/Button';

export type BaseModalProps = {
  open: boolean;
  onClose: () => void;
  onUpdate?: () => void;
};

function WarningInfoModal({ onClose, open }: BaseModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      content={
        <div className="modal-window warning-window">
          <div className="modal-window-top">
            <span className="modal-window-top-title">Add Warnings</span>
            <div className="modal-window-top-close" onClick={onClose} />
          </div>
          <div className="modal-window-content warning-window-content">
            <span className="warning-window-text">
              Please use this option if your video contains elements that may be considered
              sensitive content. This will alert users in advance about potential discomfort or
              sensitivity issues.
            </span>
            <span className="warning-window-text">Types of Warnings:</span>
            <span className="warning-window-text">
              <b>Sensitive Content/blood images:</b> The video may include materials that could be
              perceived as inappropriate or distressing (e.g., traumatic scenes, violent situations,
              or emotionally intense content) or contain images involving blood, injuries, or
              surgical procedures.
            </span>
            <span className="warning-window-text">
              <b>Violent content:</b> This warning applies if your video includes any form of
              violent content or depicts acts of aggression that could potentially disturb or
              trigger viewers.
            </span>
            <span className="warning-window-text">
              <b>Video contains profanity:</b> The video may contain offensive language or swear
              words that may upset viewers.
            </span>
            <span className="warning-window-text">
              Please be careful when selecting the appropriate categories to avoid misunderstandings
              and ensure a comfortable experience for the viewers.
            </span>
          </div>
          <div className="modal-window-bottom">
            <div className="modal-window-bottom-buttons">
              <Button
                className={'modal-window-bottom-submit-button'}
                label={'Got It'}
                loading={false}
                onClick={onClose}
                type={'button'}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default WarningInfoModal;
