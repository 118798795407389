import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formCheckErrors, formGenerateErrorsObject } from '../../../../core/form';
import Button from '../../../../components/ui/Button';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { apiTaskCreate } from '../../../../actions/taskActions';
import ProducerSelect from './components/ProducerSelect';
import ClientSearch from './components/ClientSearch';
import Switcher from '../../../../components/ui/Switcher';
import { renderField } from '../../../../fields';
import { taskFields } from '../../../../fields/task';
import TaskTypes from '../components/TaskTypes';
import PageNotFound from '../../../pagenotfound/PageNotFound';

const errorsList = {
  title: 'required',
  description: 'required',
};

const initialValues: any = {
  title: '',
  description: '',
  location: null,
  budget: '',
  budget_discussed: false,
  deadline: moment().add(1, 'days').toDate(),
  producer: null,
  client: null,
  contact_phone: '',
  contact_name: '',
  types: [],
};

function PanelTaskCreate() {
  const token = useSelector(state => state.user.token);
  const user = useSelector(state => state.user.user);

  const navigate = useNavigate();

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList));
  const [submitLoading, setSubmitLoading] = useState(false);

  const getFieldActions = (key: string) => {
    return {
      value: values[key],
      error: errors[key],
      onChange: (value: any) => {
        setValues({
          ...values,
          [key]: value,
        });
        setErrors({
          ...errors,
          [key]: '',
        });
      },
    };
  };

  const changeField = (field: string, value: any) => {
    setValues({ ...values, [field]: value });
    setErrors({ ...errors, [field]: '' });
  };

  const submit = async () => {
    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({ ...errorResponse.errors });
      return;
    }

    setSubmitLoading(true);

    try {
      const apiResponse = await apiTaskCreate(token, {
        ...values,
        client_id: values.client !== null && values.client.id,
        producer_id: values.producer !== null && values.producer.user.id,
      });
      if (apiResponse.status) {
        navigate(`/panel/tasks/${apiResponse.task_id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (user.user && parseInt(user.user.admin) === 0 && parseInt(user.user.producer) === 0) {
    return <PageNotFound />;
  }

  return (
    <>
      <div className="video-page-top">
        <div
          onClick={() => {
            navigate('/panel/tasks');
          }}
          className="video-page-top-back-icon"
        >
          <i className="fa fa-angle-left" />
        </div>
        <span className="video-page-top-title">Create task</span>
        <div className="video-page-top-right"></div>
      </div>
      <div className="create-page-row row" style={{ marginTop: 20 }}>
        <div className="create-page-block">
          <span className="create-page-block-title">General Information</span>
          <div className="create-page-block-inner">
            {renderField({
              ...taskFields.title,
              ...getFieldActions('title'),
              className: 'create-page-block-element',
              required: true,
            })}
            {renderField({
              ...taskFields.location,
              ...getFieldActions('location'),
              className: 'create-page-block-element',
              required: true,
            })}
            <div className="create-page-block-dop-row row">
              <div className="row">
                <div className="create-page-block-dop-row-field">
                  {renderField({
                    ...taskFields.deadline,
                    ...getFieldActions('deadline'),
                    className: 'create-page-block-element',
                    required: true,
                  })}
                </div>
                <div className="create-page-block-dop-row-field">
                  {renderField({
                    ...taskFields.budget,
                    ...getFieldActions('budget'),
                    className: 'create-page-block-element',
                    required: true,
                    disabled: values.budget_discussed,
                    onChange: (value: any) => {
                      setValues({
                        ...values,
                        budget: value,
                      });
                      setErrors({
                        ...errors,
                        budget: '',
                      });
                    },
                  })}
                </div>
              </div>
              <div className="create-page-block-dop-row-right">
                <Switcher
                  label={'without budget'}
                  checked={values.budget_discussed}
                  onChange={value => {
                    setValues({
                      ...values,
                      budget: value ? '' : values.budget,
                      budget_discussed: value,
                    });
                  }}
                />
              </div>
            </div>
            {renderField({
              ...taskFields.description,
              ...getFieldActions('description'),
              className: 'create-page-block-element',
              required: true,
            })}
          </div>
          <span className="create-page-block-title second-title">Types of work</span>
          <div className="create-page-block-inner">
            <TaskTypes
              selectedTypes={values.types}
              onChange={_types => {
                changeField('types', _types);
              }}
            />
          </div>
        </div>
        <div className="create-page-block">
          <span className="create-page-block-title">Members</span>
          <div className="create-page-block-inner">
            <ProducerSelect
              selectedProducer={values.producer}
              onChange={(producer: any) => {
                changeField('producer', producer);
              }}
            />
          </div>
          {user.company_id === 1 && (
            <>
              <span className="create-page-block-title second-title">Contact information</span>
              <div className="create-page-block-inner">
                <ClientSearch
                  selectedClient={values.client}
                  onChange={(client: any) => {
                    setValues((values: any) => {
                      return {
                        ...values,
                        client,
                        contact_name: `${client.user.first_name} ${client.user.last_name}`,
                        contact_phone: client.contact_phone,
                      };
                    });
                  }}
                />
                <div className="row">
                  <div className="create-page-block-dop-row-field">
                    {renderField({
                      ...taskFields.contactPhone,
                      ...getFieldActions('contact_phone'),
                      className: 'create-page-block-element',
                      required: true,
                    })}
                  </div>
                  <div className="create-page-block-dop-row-field">
                    {renderField({
                      ...taskFields.contactName,
                      ...getFieldActions('contact_name'),
                      className: 'create-page-block-element',
                      required: true,
                    })}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="bottom-area">
        <div className="bottom-area-inner">
          <Button className={'gray'} label={'Cancel'} to={'/panel/tasks'} type={'link'} />
          <div className="bottom-area-right">
            <Button
              className={'blue bottom-area-submit-button'}
              label={'Submit'}
              loading={submitLoading}
              onClick={submit}
              type={'button'}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PanelTaskCreate;
