import { CustomField } from './index';

export const contactFields: {
  [name: string]: CustomField;
} = {
  firstName: {
    id: 'firstName',
    type: 'input',
    label: 'First Name',
  },
  lastName: {
    id: 'lastName',
    type: 'input',
    label: 'Last Name',
  },
  email: {
    id: 'email',
    type: 'input',
    label: 'Email',
  },
  message: {
    id: 'message',
    type: 'textarea',
    options: {
      minRows: 4,
    },
    label: 'What can we help you with?',
  },
};
