import React from 'react';

function Checkbox({
  label,
  checked,
  onChange,
  className,
}: {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
}) {
  return (
    <label className={`custom-checkbox ${className && className}`}>
      <input
        type="checkbox"
        checked={checked}
        onChange={event => {
          onChange(event.target.checked);
        }}
      />
      <div className="custom-checkbox-checkmark">
        <div className="custom-checkbox-checkmark-inner" />
      </div>
      <span className="custom-checkbox-label">{label}</span>
    </label>
  );
}

export default Checkbox;
