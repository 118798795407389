import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Tabs from '../../../../components/ui/Tabs';
import Loading from '../../../../components/ui/Loading';
import { apiBriefGet } from '../../../../actions/briefActions';
import { useNavigate, useParams } from 'react-router-dom';
import { CLOUDFRONT_URL } from '../../../../core/common';
import BriefPageGeneralInfo from './components/BriefPageGeneralInfo';
import StringersWithSend from '../../../../components/stringer/StringersWithSend';
import PageNotFound from '../../../pagenotfound/PageNotFound';

function PanelBriefPage() {
  const navigate = useNavigate();
  const token = useSelector(state => state.user.token);
  const user = useSelector(state => state.user);
  const { id } = useParams();

  const [brief, setBrief] = useState<any>(null);
  const [proposes, setProposes] = useState<any>([]);

  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('General Info');

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const response = await apiBriefGet(token, id);
    if (response.event !== null) {
      setBrief(response.event);
      setProposes(response.proposes);
      setLoading(false);
    }
  };

  if (user.user && parseInt(user.user.admin) === 0 && parseInt(user.user.ugc) === 0) {
    return <PageNotFound />;
  }

  if (loading || brief === null) {
    return <Loading />;
  }

  return (
    <>
      <div className="video-page-top">
        <div
          onClick={() => {
            navigate('/panel/briefs');
          }}
          className="video-page-top-back-icon"
        >
          <i className="fa fa-angle-left" />
        </div>
        <span className="video-page-top-title">Briefs</span>
        <div className="video-page-top-right" />
      </div>
      <div className="task-page">
        <div className="task-page-inner">
          <div className="task-page-top">
            <div className="task-page-top-content">
              <img
                src={`${CLOUDFRONT_URL}${brief.static_image}`}
                alt=""
                className="task-page-top-map"
              />
              <div className="task-page-top-info">
                <span className="task-page-top-info-name">{brief.title}</span>
                <span className="task-page-top-info-location">
                  {brief.location_text !== '' && <i className="fa fa-map-marker" />}{' '}
                  {brief.location_text}
                </span>
              </div>
              <div className="task-page-points">
                <span className={`task-page-point ${brief.status === 1 && 'active'}`}>Active</span>
                <span className={`task-page-point ${brief.status === 2 && 'active'}`}>Closed</span>
              </div>
              <Tabs
                className={'brief-tabs'}
                tabs={['General Info', 'Stringers']}
                currentTab={currentTab}
                onChange={tab => setCurrentTab(tab)}
              />
            </div>
          </div>
          {currentTab === 'General Info' && (
            <BriefPageGeneralInfo
              brief={brief}
              onChangeStatus={(status: string) => {
                setBrief({
                  ...brief,
                  status,
                });
              }}
              onChangeTitle={(title: string) => {
                setBrief({
                  ...brief,
                  title,
                });
              }}
              onChangeLocationText={(locationText: string) => {
                setBrief({
                  ...brief,
                  location_text: locationText,
                });
              }}
            />
          )}
          {currentTab === 'Stringers' && (
            <StringersWithSend
              obj={brief}
              type={'brief'}
              proposes={proposes}
              onChangeProposes={_proposes => {
                setProposes(_proposes);
              }}
              initialLocation={{
                country: brief.location_country,
                state: brief.location_state,
                city: brief.location_city,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default PanelBriefPage;
