import React from 'react';
import ConfirmModal, { BaseModalProps } from './ConfirmModal';
import { useDispatch } from 'react-redux';
import { userSignOutAction } from '../../reducers/user';
import { useNavigate } from 'react-router-dom';

function SignOutModal({ onClose, open }: BaseModalProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <ConfirmModal
      open={open}
      onClose={onClose}
      onUpdate={() => {
        dispatch(userSignOutAction());
        navigate('/');
      }}
      title="Sign Out?"
      message="Are you sure you want to sign out from your profile?"
      submitButtonText="Sign Out"
      cancelButtonText="Cancel"
    />
  );
}

export default SignOutModal;
