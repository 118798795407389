import React, { useState } from 'react';
import {
  apiBriefClose,
  apiBriefInviteStringers,
  apiBriefsGet,
} from '../../../actions/briefActions';
import TablePage, {
  TableAction,
  TableConfig,
  TableFilter,
} from '../../../components/table/TablePage';
import BriefCreateModal from './components/BriefCreateModal';
import { StringerTable } from '../../../types/tables';
import { useSelector } from 'react-redux';
import PageNotFound from '../../pagenotfound/PageNotFound';

const configHeads = [
  {
    key: 'id',
    type: 'checkbox',
  },
  {
    name: 'Title',
    width: null,
    key: 'title',
    type: 'link',
    options: {
      href: '/panel/briefs/',
    },
  },
  {
    name: 'Location',
    width: '230px',
    key: 'location_text',
    type: 'string',
  },
  {
    name: 'Event date',
    width: '90px',
    key: 'start_date',
    type: 'date',
    options: {
      format: 'DD/MM/YYYY',
    },
  },
  {
    name: 'Deadline',
    width: '90px',
    key: 'deadline',
    type: 'date',
    options: {
      format: 'DD/MM/YYYY',
    },
  },
  {
    name: 'Status',
    width: '145px',
    key: 'status',
    type: 'status',
    options: {
      circle: {
        1: 'orange',
        2: 'green',
      },
      label: {
        2: 'Closed',
        1: 'Active',
      },
    },
  },
  {
    name: 'Created at',
    width: '90px',
    key: 'created_at',
    type: 'date',
    options: {
      format: 'DD/MM/YYYY HH:mm:ss',
    },
  },
];

const configFilters: TableFilter[] = [
  {
    id: 'Status',
    type: 'select',
    label: 'Status',
    options: {
      label: 'Please select status',
      elements: ['Active', 'Closed'],
    },
  },
  {
    id: 'Created At',
    type: 'date',
    label: 'Created At',
  },
];

const config: TableConfig = {
  title: 'Briefs',
  key: 'events',
  functions: {
    loadElementsMethod: apiBriefsGet,
    convertElementsMethod: null,
  },
  options: {
    hasSearch: true,
    hasSwitcher: false,
  },
  heads: configHeads,
  filters: configFilters,
};

function PanelBriefs() {
  const token = useSelector(state => state.user.token);
  const user = useSelector(state => state.user);

  const [createModalOpen, setCreateModalOpen] = useState(false);

  if (user.user && parseInt(user.user.admin) === 0 && parseInt(user.user.ugc) === 0) {
    return <PageNotFound />;
  }

  return (
    <>
      <BriefCreateModal
        open={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
        }}
      />
      <TablePage
        config={{
          ...config,
          options: {
            ...config.options,
            createButton: {
              label: 'Create brief',
              action: () => {
                setCreateModalOpen(true);
              },
            },
          },
          actions: [
            {
              label: 'Close',
              name: 'close',
              icon: 'delete',
              successCallback: (ids: number[], elements: StringerTable[]): StringerTable[] => {
                return elements.map(element => {
                  if (ids.indexOf(element.id) !== -1) {
                    return {
                      ...element,
                      status: 2,
                    };
                  }
                  return element;
                });
              },
              function: async (ids: number[]) => {
                await apiBriefClose(token, ids);
              },
            },
          ],
        }}
      />
    </>
  );
}

export default PanelBriefs;
