import React, { useEffect, useState } from 'react';
import Modal from '../../../ui/Modal';
import { Portfolio } from '../../../../types/models';
import moment from 'moment';

function StringerModalPortfolio({
  open,
  portfolio,
  onClose,
  onUpdate,
}: {
  open: boolean;
  portfolio: Portfolio | null;
  onClose: () => void;
  onUpdate: () => void;
}) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      content={
        portfolio !== null && (
          <div className="portfolio-modal">
            <span className="portfolio-modal-close" onClick={onClose} />
            <span className="portfolio-modal-title">{portfolio.title}</span>
            <div className="portfolio-modal-content">
              <span className="portfolio-modal-text">{portfolio.description}</span>
              <div className="portfolio-modal-elements">
                <span className="portfolio-modal-element">
                  <span className="portfolio-modal-element-label">Links:</span>
                  {portfolio.links.map(link => {
                    return (
                      <>
                        <br />
                        <a href={'#'} target={'_blank'}>
                          {link}
                        </a>
                      </>
                    );
                  })}
                </span>
                <span className="portfolio-modal-element">
                  <span className="portfolio-modal-element-label">Filming Date:</span>{' '}
                  {moment(portfolio.filmingDate).format('DD/MM/YYYY')}
                </span>
                <span className="portfolio-modal-element">
                  <span className="portfolio-modal-element-label">Client:</span> {portfolio.client}
                </span>
              </div>
            </div>
          </div>
        )
      }
    />
  );
}

export default StringerModalPortfolio;
