import moment from 'moment';

export const formatDate = (stringDate: string, format: string) => {
  if (!moment(stringDate).isValid()) {
    return '';
  }

  return moment(stringDate).format(format);
};

export const getDateFromString = (string: string) => {
  if (!moment(string).isValid()) {
    return null;
  }

  return moment(string).toDate();
};

export const isEmpty = (string: string) => {
  if (string === undefined || string === null) {
    return true;
  }

  if (string.trim().length === 0) {
    return true;
  }

  return false;
};

export const convertFilters = (
  filters: any,
  search: string,
  page: number,
  mode: string,
  sort = ''
) => {
  const result: any = {};

  filters.forEach((filter: any) => {
    if (result[filter.id] === undefined) {
      result[filter.id] = filter.value;
    } else {
      result[filter.id] = result[filter.id].split(',');
      result[filter.id].push(filter.value);
      result[filter.id] = result[filter.id].join(',');
    }
  });

  if (search !== '') {
    result['search'] = search;
  }

  if (page > 0) {
    result['page'] = page + 1;
  }

  if (mode !== '') {
    result['mode'] = mode;
  }

  if (sort !== '' && sort !== 'Newest first') {
    result['sort'] = sort;
  }

  return result;
};

export const getTaskStatus = (task: any) => {
  if (task.status === 0) {
    return 'Rejected';
  }

  if (task.status === 3) {
    return 'Rejected';
  }

  if (task.status === 1) {
    return 'On Moderation';
  }

  if (task.status === 2 && task.stringer_user_id === null) {
    return 'Searching for a stringer';
  }

  if (
    task.status === 2 &&
    task.stringer_user_id !== null &&
    parseInt(task.material_accepted) === 0
  ) {
    return 'Production';
  }

  if (task.status === 2 && parseInt(task.material_accepted) === 1) {
    return 'Completed';
  }

  return null;
};
