import React, { useState } from 'react';
import './Faq.scss';

const questions = [
  {
    question: 'What is StringersHub?',
    answer:
      'StringersHub is a marketplace that connects professional videographers (content creators) and eyewitnesses with media producers and editors who need video content.',
  },
  {
    question: 'How do I make sure my video get selected?',
    answer:
      "We're looking for the latest news content from all over the world. Keep in mind that news covers a broad range of topics such as politics, economy, crime, weather, celebrity, sports, extraordinary stories, events, etc. There are hundreds of content buyers on the platform who specialize in different topics. So upload your recent stories on the platform today.",
  },
  {
    question: 'How do I get paid?',
    answer:
      "Once your balance is more than $50 we'll attempt to make payment to your payment account. If your video has been purchased by a media buyer - you will receive a notification and get paid for it. On-demand video is paid according to a pre-approved budget. Videographers and eyewitnesses get paid on the 10th day of the following month. For mass payments, we use the service partner Tipalti. We will send a link to your email address specified during registration to activate your account. Payments will be made in several ways: PayPal (fee of $2+2% from the transfer amount, for example, when you receive $100 Commission is $4), Wire transfer (fee $26, for example, when you receive $100 Commission - $26), Check (fee is $3, for example, when you receive $100 Commission - $3), eCheck (fee $5, for example, when you receive $100 Commission - $5), ACH (fee $5, for example, when you receive a $100 Commission - $5). Please note that the commission is paid by the recipient.",
  },
  {
    question: 'Personal data.',
    answer:
      'Carefully check all the fields in your profile when registering, as well as the necessary details for payment. If you enter incorrect information to get money, the stringer pays compensation for additional transactions. You are responsible for entering your personal data correctly.',
  },
  {
    question: 'How do I upload videos?',
    answer:
      'To upload the video, you need to register or login on the platform. Click Upload video in your personal account, fill in a description, add tags and Send.',
  },
  {
    question: 'Terms of sale and distribution.',
    answer:
      "The platform provides direct sales of UGC content, as well as shares with partners to generate revenue (both from sales and views). Direct sales are realized when any media buys a video. The sale is not agreed with the author before the transaction itself. The platform's partners include AP Video Hub, Newsflare, and Video Elephant. Partners provide sales reports with a certain frequency, usually at the end of the month. StringersHub reserves the right to distribute videos of stringers in social networks for more effective promotion, as well as to share content with partners.",
  },
  {
    question: 'What type of content can I upload on the platform?',
    answer:
      'Our main goal is to provide content buyers with relevant video content, that’s why news video content is more relevant for us. Nevertheless, you can upload any kind of video. For example, it can be political or social content, sports or entertainment events, etc.',
  },
  {
    question: 'Video upload requirements',
    answer: `
    <ul>
     <li>Please ensure that you insert the exact filming date and location (it’s necessary to specify the city where the video was filmed, not only the county).</li>
     <li>Ensure that the video lasts no less than 10 seconds and no more than 8 minutes.</li>
     <li>The video shouldn't have any watermarks or logo, no copyrighted music or excessive editing should be present in the video.</li>
     <li>Please give as many details as possible when filling in the description field.</li>
    </ul>
    `,
  },
  {
    question: 'Which video formats does StringersHub accept?',
    answer:
      'The recommended format is .MP4 (H.264), .MTS, .MOV, .AVI, .FLV video formats are acceptable but it might take longer time to process. A video can be shot on a camera or smartphone.',
  },
  {
    question: 'What kind of video are not accepted?',
    answer:
      'You cannot upload your showreel with your watermark as unique content on the platform. It’s prohibited to upload content of violence, pornography, bestiality, videos depicting nudity, spam or advertising. If you upload such content, your account will be banned.',
  },
];

function Faq() {
  return (
    <div className="faq-block">
      <div className="faq-block-container">
        <span className="faq-block-title">FAQ</span>
        <div className="faq-block-content">
          <div className="faq-block-questions">
            {questions.map(element => (
              <FaqQuestion answer={element.answer} question={element.question} />
            ))}
          </div>
          <div className="faq-block-content-image">
            <img src="/images/operator.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

function FaqQuestion({ answer, question }: any) {
  const [open, setOpen] = useState(false);

  return (
    <div
      className="faq-block-question"
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div className="faq-block-question-top">
        <span className={`faq-block-question-label ${open && 'active'}`}>{question}</span>
        {open ? (
          <i className="fa fa-minus faq-block-question-icon" />
        ) : (
          <i className="fa fa-plus faq-block-question-icon" />
        )}
      </div>
      {open && (
        <div className="faq-block-question-text" dangerouslySetInnerHTML={{ __html: answer }} />
      )}
    </div>
  );
}

export default Faq;
