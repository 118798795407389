import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CLOUDFRONT_URL } from '../core/common';

function Brief({ brief }: any) {
  return (
    <div className="task-card">
      <div className="task-card-inner">
        <Link to={`/briefs/${brief.hash}`} className="task-card-image">
          <div className="task-card-image-inner">
            <img
              src={`${CLOUDFRONT_URL}${brief.static_image}`}
              alt=""
              className="task-card-thumb"
            />
          </div>
        </Link>
        <div className="task-card-content-area">
          <Link to={`/briefs/${brief.hash}`} className="task-card-title">
            {brief.title}
          </Link>
          <div className="task-card-content">
            <div className="task-card-content-element row">
              <span className="task-card-content-element-label">Location</span>
              <span className="task-card-content-element-value">{brief.location_text}</span>
            </div>
            <div className="task-card-content-element row">
              <span className="task-card-content-element-label">Event Date</span>
              <span className="task-card-content-element-value">
                {moment(brief.start_date).format('DD/MM/YYYY')}
              </span>
            </div>
            <div className="task-card-content-element row">
              <span className="task-card-content-element-label">Deadline</span>
              <span className="task-card-content-element-value">
                {moment(brief.deadline).format('DD/MM/YYYY')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Brief;
