import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formCheckErrors, formGenerateErrorsObject } from '../../../../core/form';
import { apiStringerUpdateGeneralInformation } from '../../../../actions/stringerActions';
import Modal from '../../../ui/Modal';
import Field from '../../../ui/Field';
import Messengers from '../../../messengers/Messengers';
import Button from '../../../ui/Button';

const errorsList: any = {
  firstName: 'required',
  lastName: 'required',
  phone: 'required',
  location: 'location',
};

const initialValues: any = {
  firstName: '',
  lastName: '',
  phone: '',
  aboutMe: '',
  location: null,
  messengers: [],
};

function StringerModalGeneralInfo({ open, stringer, onClose, onUpdate }: any) {
  const token = useSelector(state => state.user.token);
  const [values, setValues] = useState<any>(initialValues);
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList));
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    setValues({
      ...values,
      firstName: stringer.user.first_name,
      lastName: stringer.user.last_name,
      phone: stringer.phone,
      aboutMe: stringer.about_me,
      location: {
        location_text: stringer.location_text,
        location_place_id: stringer.location_place_id,
        location_lat: stringer.location_lat,
        location_lng: stringer.location_lng,
        location_country: stringer.location_country,
      },
      messengers: stringer.messengers,
    });
  }, [stringer]);

  const changeField = (field: string, value: any) => {
    setValues({ ...values, [field]: value });
    setErrors({ ...errors, [field]: '' });
  };

  const submit = async () => {
    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({ ...errorResponse.errors });
      return;
    }

    setSubmitLoading(true);

    try {
      const apiResponse = await apiStringerUpdateGeneralInformation(
        token,
        values.firstName,
        values.lastName,
        values.phone,
        values.location,
        values.aboutMe,
        values.messengers
      );
      if (apiResponse.status) {
        onUpdate();
        setSubmitLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      content={
        <div className="modal-window">
          <div className="modal-window-top">
            <span className="modal-window-top-title">General Information</span>
            <div className="modal-window-top-close" onClick={() => onClose()} />
          </div>
          <div className="modal-window-content">
            <div className="modal-window-content-inner">
              <div className="modal-window-row row">
                <div className="modal-window-col">
                  <Field
                    id="profileFirstName"
                    label="First Name *"
                    type="input"
                    error={errors.firstName}
                    value={values.firstName}
                    onChange={value => changeField('firstName', value)}
                  />
                </div>
                <div className="modal-window-col">
                  <Field
                    id="profileLastName"
                    label="Last Name *"
                    type="input"
                    error={errors.lastName}
                    value={values.lastName}
                    onChange={value => changeField('lastName', value)}
                  />
                </div>
              </div>
              <div className="modal-window-row row">
                <div className="modal-window-col">
                  <Field
                    id="profileLocation"
                    label="Location *"
                    type="location"
                    error={errors.location}
                    value={values.location}
                    onChange={value => changeField('location', value)}
                  />
                </div>
                <div className="modal-window-col">
                  <Field
                    id="profilePhone"
                    label="Phone *"
                    type="input"
                    error={errors.phone}
                    value={values.phone}
                    onChange={value => changeField('phone', value)}
                  />
                </div>
              </div>
              <div className="modal-window-row">
                <Messengers
                  values={values.messengers}
                  onChange={(messengers: any) => {
                    console.log('messengers', messengers);
                    changeField('messengers', messengers);
                  }}
                />
              </div>
              <div className="modal-window-row row">
                <Field
                  id="profileAboutMe"
                  label="About you *"
                  type="textarea"
                  options={{
                    minRows: 5,
                  }}
                  error={errors.aboutMe}
                  value={values.aboutMe}
                  onChange={value => changeField('aboutMe', value)}
                />
              </div>
            </div>
          </div>
          <div className="modal-window-bottom">
            <div className="modal-window-bottom-buttons">
              <button className="modal-window-bottom-cancel-button" onClick={onClose}>
                Cancel
              </button>
              <Button
                className={'modal-window-bottom-submit-button'}
                label={'Save'}
                loading={submitLoading}
                onClick={submit}
                type={'button'}
              />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default StringerModalGeneralInfo;
